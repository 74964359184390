<div class="container h-full p-4">
    <div style="width:100%"
        class="bg-primary-default h-10 rounded-t-md flex flex-row items-center justify-between pl-5 pr-5">
        <span class="float-left text-white font-medium">Select Items</span>
        <div class="flex flex-row">
          <button
          title="Add Filter"
          class="text-white filter-btn h-full flex flex-row cursor-pointer pl-2 pr-1"
          [disabled]="headers.length === 0"
          (click)="onFilter()"
          >
            <mat-icon class="mt-auto">filter_list</mat-icon>
        </button>

        <button
              title="Clear Filter"
              class="text-white filter-btn h-full flex flex-row cursor-pointer pl-2 pr-1"
              [ngClass]="this.filters?.length == 0 ||
              this.filters == undefined ? 'disable' : ''"
              [disabled]="
              this.filters?.length == 0 ||
              this.filters == undefined
            "
            (click)="onClearFilter()"
            >
            <mat-icon class="mt-auto">filter_list_off</mat-icon>
          </button>
           
        </div>
      </div>
    
    <ngx-datatable class=" material w-full h-full overflow-y: auto" 
    #table
    style="height: calc(100% - 28px); width: 100%; overflow-y: hidden"
    [columnMode]="columnMode" 
    [headerHeight]="20" 
    [footerHeight]="40" 
    [rowHeight]="30" 
    [rows]="rows"
    [limit]="pageSize"
    [scrollbarV]="true" 
    [externalPaging]="true"
    [externalSorting]="true"
    [virtualization]="false"
    [selectionType]="SelectionType.single"
    [count]="count" 
    [offset]="offset"
    [sortType]="sortType"
    [selected]="!!selectedItem?[selectedItem]:[]"
    (page)="onPage($event)"
    (select)='onItemSelected($event)'
    (sort)="onSort($event)"

    >

      <!-- Checkbox Column -->
  <ngx-datatable-column [width]="30" [sortable]="false" *ngIf="isMultiSelect">
    <ng-template ngx-datatable-header-template>
      <mat-checkbox type="checkbox" color="primary" [checked]="isAllSelected()" (change)="toggleSelectAll()" />
    </ng-template>
    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
      <mat-checkbox type="checkbox" color="primary" [(ngModel)]="row.selected" (ngModelChange)="onSelect(row)" />
    </ng-template>
  </ngx-datatable-column>
   
    <!-- <ngx-datatable-column name="Id" prop="id"  [sortable]="true" [width]="100"></ngx-datatable-column> -->
    <ngx-datatable-column name="Code" prop="code" [sortable]="true" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Name" prop="name" [sortable]="true" [width]="200"></ngx-datatable-column>
  
    <ngx-datatable-footer *ngIf="true">
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-curPage="curPage"
          let-offset="offset"
        >
          <div style="padding: 5px 5px">
            <div>
              <!-- Rows: {{ rowCount !== "NaN" ? rowCount : 0 }} | Page Size: 30 | 
              Current Page: {{ curPage !== "NaN" ? curPage : 0 }} -->
              Rows: {{ rowCount !== "NaN" ? rowCount : 0 }} | Page Size:
              {{ pageSize !== "NaN" ? pageSize : 0 }} | Current Page:
              {{ curPage !== "NaN" ? curPage : 0 }}
            </div>
          </div>
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!(rowCount / pageSize > 1)"
            (change)="table.onFooterPage($event)"
          >
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer>
      </ngx-datatable>
  </div>
  <div class="p-4 button-group" align="end">
    <button mat-raised-button class="submit" [ngClass]="!isMultiSelect ? (selectedItem === '') || (selectedItem && selectedItem.length === 0) : (currentSelectingItems.length === 0) ? 'disable' : ''"   [disabled]="!isMultiSelect ? (selectedItem === '') || (selectedItem && selectedItem.length === 0) : (currentSelectingItems.length === 0)"  color="primary" (click)="onSubmit()">
      Submit
    </button>
    <button mat-raised-button (click)="onCancel()" class="ml-2 cancel-btn">
      Cancel
    </button>
  </div>
  
  
