<div class="container">
    <mat-form-field appearance="outline" class="example-chip-list">
        <mat-chip-grid #chipGrid>
            <mat-chip-row *ngFor="let item of currentRuleValue" (removed)="remove(item)" [editable]="true"
                (edited)="edit(item, rule, $event)" [title]="item" [aria-description]="'press enter to edit ' + item">
                {{item}}
                <button matChipRemove [attr.aria-label]="'remove ' + item">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <ng-container>
                <input #multiInput autofocus [placeholder]="placeholder"
                    [type]="['string', 'number', 'date'].includes(type) ? 'text' : type" [matChipInputFor]="chipGrid"
                    (selectionchange)="handleValue($event)" autocomplete="off" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                    (matChipInputTokenEnd)="add($event, type)" />
            </ng-container>
        </mat-chip-grid>
    </mat-form-field>
</div>
<div class="py-4" align="end">
    <button mat-raised-button class="submit-btn" [ngClass]="currentRuleValue.length === 0 ? 'disabled-btn' : ''" [disabled]="currentRuleValue.length === 0" color="primary" (click)="onSubmit()">
        Submit
    </button>
    <button mat-raised-button color="accent" (click)="onCancel()" class="ml-2 cancle-button">
        Cancel
    </button>
</div>