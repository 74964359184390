import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxAngularQueryBuilderModule, QueryBuilderComponent, QueryBuilderConfig } from 'ngx-angular-query-builder';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { EntityService } from 'src/app/services/entity.service';
import { Router } from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatInputModule } from '@angular/material/input';
import { LookupDialogComponent } from '../lookup-dialog/lookup-dialog.component';
import * as moment from 'moment-timezone';
import { unixTimeStamp } from 'src/app/services/moment-date-format.service';
import { InputMultiTagComponent } from '../input-multi-tag/input-multi-tag.component';

@Component({
  selector: 'app-filter-conditions',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    NgxMatSelectSearchModule,
    MatMenuModule,
    NgxAngularQueryBuilderModule,
    FormsModule,
    NgxMatDatetimePickerModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatInputModule
  ],
  templateUrl: './filter-conditions.component.html',
  styleUrls: ['./filter-conditions.component.scss']
})
export class FilterConditionsComponent {
  @ViewChild('queryBuilder') public queryBuilder!: QueryBuilderComponent;
  form!: FormGroup;
  config!: QueryBuilderConfig;
  currentFilteredItems: any = [];
  displayProp: any;
  dialogReference: any = null;
  maxCount: number = 50;
  dataLength: number = 0;
  count!: number;
  pageSize: number = 100;
  source: any;
  @Input() public data: any;
  @Output() public filters: EventEmitter<any> = new EventEmitter()
  status: any[] = [
    {
      name: 'Validation succeeded',
      value: 'true'
    },
    {
      name: 'Validation failed',
      value: 'false'
    }
  ];
  initialQuery: any;
  edited = false;

  constructor(
    public fb: FormBuilder,
    private entityService: EntityService,
    private route: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<FilterConditionsComponent>,
    @Inject(MAT_DIALOG_DATA) public lookupData?: any
  ) {

  }

  ngOnInit(): void {
    this.dataLength = Object.keys(this.lookupData).length;
    this.data = Object.keys(this.lookupData).length > 0 ? this.lookupData : this.data;
    this.data.columns = this.data.columns.filter((el: any) => !['validationstatus', 'id'].includes(el.name));
    const data = this.data;
    console.log(data?.displayFormat, 'format')
    this.displayProp =
      data?.displayFormat === "code"
        ? "code"
        : data.displayFormat === "codename"
          ? "code|name"
          : "name|code";
    data.query =
      data && data.query
        ? data.query
        : {
          condition: "and",
          rules: [],
        };
        data.columns?.forEach((itm: any, i: number) => {
          if (itm.name === "code") {
            data.columns?.splice(i, 1);
            data.columns?.unshift(itm);
          }
        });
    this.config =
      data && data.config ? data.config : this.createConfig(data.columns || []);

    if (data.query.rules.length > 0) {
      this.addLookupData(data.query.rules);
    }

    this.form = this.fb.group({
      query: [data.query]
    })
    this.initialQuery = JSON.parse(JSON.stringify(data.query))
    // dialogRef.disableClose = true;
    //HANDLE THIS FUNCTION FOR REMOVE THE INPUT FIELD DEPENDS UPON THE OPERATOR TYPE
    QueryBuilderComponent.prototype.getInputType = function (field, operator) {
      if (this.config.getInputType) {
        return this.config.getInputType(field, operator);
      }
      if (!this.config.fields[field]) {
        return null; //MY CODE
        // throw new Error("No configuration for field '" + field + "' could be found! Please add it to config.fields."); // EXISTING CODE
      }
      var type = this.config.fields[field].type;
      switch (operator) {
        case 'Is not NULL':
          return null; // No displayed component
        case 'Is NULL':
          return null; // No displayed component
        default:
          return type;
      }
    };
    if (this.form.value?.query?.rules && this.form.value?.query?.rules?.length !== 0) {
      setTimeout(() => {
        this.onApply()
      }, 500);
    }
    this.form.valueChanges
      .subscribe(() => {
        this.edited = this.compareValues(this.initialQuery, this.form.value.query)
        setTimeout(() => {
          this.onApply()
        }, 500);
      });
  }

  compareValues(a: any, b: any) {
    return JSON.stringify(a) !== JSON.stringify(b)
  }


  // HANDLE FOR ADDING FILTER FILTERED ITEMS TO EVERY RULE 
  handleAddRule(ruleset: any, addRule: Function) {
    if (!!addRule) {
      addRule();
    }
    ruleset.rules.map((item: any) => item.filteredItems = this.queryBuilder.fields);
  }
  onCancel(): void {
    this.dialogRef.close(null);
  }
  onApply(): void {
    const filterGroup: any = this.convertToFilters(this.form.value.query);
    this.filters.emit({ filter: filterGroup, query: this.form.value.query,edited:this.edited });
  }

  applyFilter() {
    const filterGroup: any = this.convertToFilters(this.form.value.query);
    this.dialogRef.close(filterGroup);
  }

  // HANDLE THIS FUNCTION FOR CHANGING THE OPERATOR AND SET VALIDATORS DEPENDS UPON THE OPERATOR
  handleOperators(value: any, rule: any, onChange?: any) {
    rule.operator = value;
    rule.value = '';
    rule.valueName = '';
    console.log(this.config, this.form, this.queryBuilder);
    const field = this.config.fields[rule.field];
    if(['LOOKUP', 'string', 'number', 'date'].includes(field?.type)){ 
      rule.isMultiSelect = ['Is any of', 'Is none of'].includes(value);
    }
    if (!!onChange) {
      onChange(rule, rule?.value || '');
    }
  }

  getDefaultValue(
    dataType: "string" | "number" | "date" | "boolean" | "category" = "string"
  ): string | number | Date | boolean {
    switch (dataType) {
      case "string":
        return "";
      case "number":
        return 0;
      case "date":
        return new Date();
      case "boolean":
        return false;
      default:
        return "";
    }
  }

  // HANDLE THIS FUNCTION FOR WHEN WE EDIT THE FILTER THEN FETCH LOOKUPDATA
  public addLookupData(item: any) {
    item.forEach((elm: any) => {
      if (elm.rules && elm.rules.length > 0) {
        this.addLookupData(elm.rules);
      }
      else {
        if (elm.type == 'date') {
          let timezone = (localStorage.getItem("timezone") as string) || "null";
          if (timezone == "normal") {
            elm.value = !elm?.isMultiSelect ? moment(elm.value as string).parseZone() : this.convertDateFormat(elm.value, false);;
          }
        }
        if(elm?.isAllSelected && elm?.isMultiSelect) {
          elm.value = ['All', ...elm.value];
        }
        this.handleLookupData(elm.field);
      }
    });
  }

  /**
   * Handle this function for selecting all values
   * @param rule any
   * @param filteredOptions any
   */
  public toggleSelectAll(rule: any, filteredOptions: any, isMultiSelect: boolean, selected: any) {
    if(isMultiSelect) {
      rule.isAllSelected = selected;
      rule.value = selected ? [...filteredOptions.map((opt: any) => opt.id), "All"] : "";
      rule.valueName = filteredOptions.map((el: any) => this.getDisplayProp(el)).join(',');
    }
    this.updateFormControlValidation(this.form?.value?.query?.rules);
  }

  /**
   * Handle this function for select and deselect the checkbox
   * @param filteredOptions any
   * @param rule any
   * @returns 
   */
  togglePerValue(filteredOptions: any, rule: any, selection: any) {
    const allSelect = filteredOptions.length === rule.value.filter((item: any) => item !== 'All').length;
    rule.isAllSelected = allSelect;
    if(!allSelect){
      selection.deselect();
    }
    else {
      selection.select();
    }
    let data = filteredOptions.filter((el: any) => rule?.value.includes(el?.id));
    rule.valueName = data.map((el: any) => this.getDisplayProp(el)).join(',');
    // Handle this for update the form validation
    this.updateFormControlValidation(this.form?.value?.query?.rules);
  }

  public convertToFilters(query: any) {
    let filters: Filter[] = [];
    let parentCondition = query.parentCondition ? query.parentCondition : query.condition
    query.rules.forEach((item: any, index: number) => {
      const operatorType = this.getOperatorType(item.operator);
      const dataTypeType = this.getDataType(this.data.columns, item.field);
      item.value = item?.isMultiSelect && item.type === 'date' ? this.convertDateFormat(item.value, true) : item.value;

      const groupFilters = item.rules
        ? this.convertToFilters({
          rules: item.rules,
          condition: item.condition,
          parentCondition: query.condition
        })
        : undefined;
      const conditionType = !!item.condition ? item.condition : query.condition;
      const filter: Filter = {
        filterType: item.rules
          ? FilterType.CONDITIONGROUP
          : FilterType.CONDITION,
        joinType: item.rules ? this.getJoinType(parentCondition) : this.getJoinType(conditionType),
        operatorType: operatorType,
        key: item.field,
        value: item?.value && Array.isArray(item?.value) ? (item?.value.filter((el: any) => el !== 'All')) : item?.value ? item?.value : this.getDefaultValue(dataTypeType),
        filters: groupFilters,
        dataType: dataTypeType,
      };
      filters.push(filter);
    });

    return filters;
  }

  public convertDateFormat(dates: string[], toISO: boolean = true): string[] {
    return dates.map((dateStr: string) => {
      if (toISO) {
        // Convert from MM/DD/YYYY to ISO
        const [month, day, year] = dateStr.split('/').map(Number);
        const date = new Date(Date.UTC(year, month - 1, day));
        return date.toISOString();
      } else {
        // Convert from ISO to MM/DD/YYYY
        const date = new Date(dateStr);
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Pad month with leading zero
        const day = ('0' + date.getDate()).slice(-2); // Pad day with leading zero
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
      }
    });
  }

  /**
   * Handle this function for update the entire form validation
   * @param rule any
   */
  public updateFormControlValidation(rules: any) {
    const hasEmptyValues = this.checkForEmptyValues(rules);
    if (hasEmptyValues) {
      this.form.controls['query'].setErrors({ 'invalid': true });
    } else {
      this.form.controls['query'].setErrors(null);
    }
  }
  
  /**
   * Handle recursive method for check the rules values
   * @param rules any
   * @returns 
   */
  private checkForEmptyValues(rules: any): boolean {
    if (!rules || rules.length === 0) {
      return true;  // If no rules, treat as empty
    }
  
    for (const el of rules) {
      // Check if current rule value is empty
      if ((Array.isArray(el?.value) && el?.value?.length === 0) || (!el?.value && !el?.rules)) {
        return true;  // Empty value found, exit early
      }
  
      // Recursively check nested rules, exit early if any are empty
      if (el?.rules?.length > 0 && this.checkForEmptyValues(el.rules)) {
        return true;
      }
    }
  
    return false;
  }

  // Function to check if the date is realistically within range
  public isValidFormatDate(date: Date): boolean {
    // Check for reasonable year range, for example between 1900 and 2100
    const year = date.getFullYear();
    return year >= 1900 && year <= 2100;
  }

  // Function to format a date as MM/DD/YYYY
  public formatDateMMDDYYYY(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');  // getMonth() is zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${month}/${day}/${year}`;
  }

  public openMultiInputDialog(rule: any, onChange: any): void {
    let field: any = this.config.fields[rule.field];
    const dialogRef = this.dialog.open(InputMultiTagComponent, {
      width: "659px",
      height: "300px",
      panelClass: 'input-multi-dialog',
      data: {
        rule: rule,
        field: field
      },
      hasBackdrop: false,
    });
    this.dialogReference = dialogRef;
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result?.data){
        rule.value = result?.data?.value;
        rule.valueName = result?.data?.value?.map((el: any) => el).join(',').toString();
        if(!!onChange) {
          onChange(rule, rule?.value);
        }
      }
    });
  }

  private getJoinType(condition: string) {
    if (condition == "and") {
      return JoinType.AND;
    } else if (condition == "or") {
      return JoinType.OR;
    } else {
      return JoinType.AND;
    }
  }

  private getDataType(
    columns: QueryColumns[] | undefined,
    name: string
  ): "string" | "number" | "date" | "boolean" | "category" {
    let dataType: "string" | "number" | "date" | "boolean" | "category" =
      "string";
    const col = columns?.find((item) => item.name === name);
    dataType = col?.dataType ? col?.dataType : "string";
    dataType = dataType === "category" ? "boolean" : dataType;
    return dataType;
  }

  private getOperatorType(operator: string) {
    let operatorType: OperatorType | undefined = undefined;
    switch (operator) {
      case "Is equal to":
        return OperatorType.EQUALS;
      case "Is not equal to":
        return OperatorType.IS_NOT_EQUAL;
      case "Is greater than":
        return OperatorType.GREATER_THAN;
      case "Is greater than or equal to":
        return OperatorType.GREATER_THAN_EQUALS;
      case "Is less than":
        return OperatorType.LESS_THAN;
      case "Is less than or equal to":
        return OperatorType.LESS_THAN_EQUALS;
      case "Contains pattern":
        return OperatorType.CONTAINS;
      case "Does not contain pattern":
        return OperatorType.NOT_CONTAINS;
      case "Is NULL":
        return OperatorType.IS_NULL;
      case "Is not NULL":
        return OperatorType.IS_NOT_NULL;
      case "Starts with":
        return OperatorType.STARTS_WITH;
      case "Ends with":
        return OperatorType.ENDS_WITH;
      case "Is any of":
        return OperatorType.IS_ANY_OF;
      case "Is none of":
        return OperatorType.IS_NONE_OF;
    }
    return operatorType;
  }

  private createConfig(columns: QueryColumns[]) {
    let config: QueryBuilderConfig = {
      fields: {},
    };
    columns.forEach((col) => {
      const dataType = col.formType === 'LOOKUP' ? col.formType : col.dataType;
      config.fields[col.name] = {
        name: col.displayName || col.name,
        type: dataType,
        operators: this.mapOperators(dataType),
        entity: col.referencedTableId,
        options: col.options,
        nullable: col.nullable,
        validator: (rule) => {
          if (['', null, undefined].includes(rule.value) && ![undefined, 'Is NULL', 'Is not NULL'].includes(rule.operator)) {
            return {
              required: {
                rule: rule,
                message: 'Field is required'
              }
            }
          }
          return null;
        }
      };
    });
    return config;
  }

  public handleLookupData(item: any, rule?: any) {
    rule ? (rule.valueName = '', rule.value = '', rule.selectedItem = '') : null;
    rule ? rule.type = this.config.fields[item].type : '';
    let field: any = this.config.fields[item];
    if (field?.options) {
      field.filteredOptions = field.options
    }
    if (item === "is_valid") {
      field.filteredOptions = this.status;
    }
    if (['string', 'LOOKUP', 'number', 'date'].includes(field?.type)) {
      !!rule ? rule.isMultiSelect = (['Is any of', 'Is none of'].includes(rule?.operator) && rule?.field === item) : '';
        if(field?.type === 'LOOKUP' && !!field?.entity){
          field?.type === 'LOOKUP' && !!field?.entity ? this.loadLookupData(field.entity, 0, [], item, [{ direction: "ASC", property: "code" }], rule) : '';
        }
    }
  }

  loadLookupData(id: string | number, pageNumber: number, filters: any, rule: any,sorters:any,rules?:any) {
    this.entityService.loadLookupData(id, pageNumber, this.pageSize, filters,sorters).subscribe((res: any) => {
      if (rule) {
        let field: any = this.config.fields[rule];
        field.options = res?.content;
        field.filteredOptions = res.content;
        field.count = res?.totalElements;
        field.totalPages = res?.totalPages;
        field.page = 0;
        rules.filteredOptions = res?.content;
      }
      else {
        this.dialogReference.componentInstance.upDatedData({
          value: res?.content,
          total: res?.totalElements,
        });
      }
    }, error => {
      if (error.status === 401) {
        localStorage.removeItem('token');
        // this.route.navigate(['/']);
      }
    })
  }

  addLookupInfo(rule:any,field:any,event:any)
  {
    const type = Array.isArray(event);
    rule.displayName=field.name;
    let data=field.filteredOptions.filter((el: any) => type ? event.includes(el.id) : el?.id === event);
    rule.valueName = data.map((el: any) => this.getDisplayProp(el)).join(',');
  }

  public handleMoreOptions(field: any) {
    field.page = field?.page + 1;
    this.entityService.loadLookupData(field.entity, field.page, this.pageSize, [],[{ direction: "ASC", property: "code" }]).subscribe((res: any) => {
      if (field?.value) {
        field.options = [...field.options, ...res?.content];
        field.filteredOptions = [...field.filteredOptions, ...res?.content];
        field.page = field?.page;
      }
      else {
        this.dialogReference.componentInstance.upDatedData({
          value: res?.content,
          total: res?.totalElements,
        });
      }
    }, error => {
      if (error.status === 401) {
        localStorage.removeItem('token');
        // this.route.navigate(['/']);
      }
    })
  }

  openPopup(rule: any, onChange?: any): void {
    let field: any = this.config.fields[rule.field];
    if(rule?.value?.length > 0 && ['', null, undefined].includes(rule?.selectedItem)){
      rule.selectedItem = field?.options.filter((el: any) => rule?.value.includes(el.id));
    }
    rule.selectedItem = rule?.value?.length === 0 && rule?.selectedItem.length > 0 ? [] : rule?.selectedItem;
    const dialogRef = this.dialog.open(LookupDialogComponent, {
      width: "700px",
      height: "620px",
      data: {
        value: field.options,
        total: field.count,
        pageSize: this.pageSize,
        selectedItem: rule.selectedItem,
        lookupId: field.entity,
        isMultiSelect: rule?.isMultiSelect
      },
      hasBackdrop: false,
    });
    this.dialogReference = dialogRef;
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.data) {
        const { data } = result;
        const isArray = Array.isArray(data);
        rule.selectedItem = data;
        rule.value = isArray ? data.map(({id}: any) => {return id}) : data?.id;
        rule.valueName = isArray ? data.map((el: any) => this.getDisplayProp(el)).join(',') : this.getDisplayProp(data);
        rule.displayName=field.name;
        if (!!onChange) {
          if(isArray) {
            data.map(({id}: any) => onChange(id, rule));
          } else {
            onChange(data?.id, rule)
          }
        }
      }
    });
    dialogRef.componentInstance.dialogEvent$.subscribe((event: any) => {
      this.loadLookupData(field.entity, event.pageNumber, event.filters,undefined,event.sorters);
    });
  }
  public handleSerch(value: any, item: any, text?: string) {
    if (text === 'fieldSearch') {
      console.log(this.config, this.data.columns);
      const items: any = this.queryBuilder.fields;
      const final = this.filterItems(items, value);
      item.filteredItems = final;
    }
    else {
      let field: any = this.config.fields[item.field];
      const final = this.filterItems(field.options, value);
      item.filteredOptions = final;
    }
  }
  // Generic filtering function
  private filterItems(items: any[], searchText: string): any[] {

    if (searchText === '') {
      return items;
    }
    return items.filter((item: any) => {
      // Check if either code or name includes the searchText
      return (
        (item.code && item.code.toString().toLowerCase().includes(searchText.trim().toLowerCase())) ||
        (item.name &&
          item.name.trim().toLowerCase().includes(searchText.trim().toLowerCase()))
      );
    });
  }

  public getDisplayProp(option: any) {
    const prop = this.displayProp?.split("|") || [];
    return prop.reduce((prev: any, cur: any) => {
      return prev === "-##"
        ? option[cur]
        : `${prev == null ? "" : prev} ${this.renderDataWithCurlyBrace(
          option[cur]
        )}`;
    }, "-##");
  }

  private renderDataWithCurlyBrace(data: any) {
    return data == null ? "" : "{" + data + "}";
  }

  private mapOperators(type: string) {
    switch (type) {
      case "string":
        return [
          "Is equal to",
          "Is not equal to",
          "Is greater than",
          "Is less than",
          "Is greater than or equal to",
          "Is less than or equal to",
          "Contains pattern",
          "Does not contain pattern",
          "Is NULL",
          "Is not NULL",
          "Is any of",
          "Is none of"
          // "Starts with",
          // "Ends with",
        ];
      case "date":
        return [
          "Is equal to",
          "Is not equal to",
          "Is greater than",
          "Is less than",
          "Is greater than or equal to",
          "Is less than or equal to",
          "Is NULL",
          "Is not NULL",
          "Is any of",
          "Is none of"
          // "IN",
        ];
      case "number":
        return [
          "Is equal to",
          "Is not equal to",
          "Is greater than",
          "Is less than",
          "Is greater than or equal to",
          "Is less than or equal to",
          "Is NULL",
          "Is not NULL",
          "Is any of",
          "Is none of"
        ];
      case "boolean":
        return ["Is equal to", "Is not equal to"];

      case "LOOKUP":
        return [
          "Is equal to",
          "Is not equal to",
          "Is NULL",
          "Is not NULL",
          "Is any of",
          "Is none of"
        ];
    }
    return [];
  }
  public changeTimeStamp(date: any) {
    date=moment(date as string).format("YYYY-MM-DD 00:00:00");
    return moment(date).parseZone();
  }
}
export interface FilterData {
  config: QueryBuilderConfig | null;
  query?: any | null;
  disabled?: boolean;
  persistValueOnFieldChange?: boolean;
  columns?: QueryColumns[];
  emptyMessage?: string;
  displayFormat?: string;
  save?: boolean;
  edit?: boolean;
  filterName?: string;
}

// export interface FilterGroup {
//   filterType: FilterType;
//   joinType: JoinType;
//   filters: Filter[];
// }

interface Filter {
  filterType: string;
  key?: string;
  value?: number | string | boolean;
  joinType: JoinType;
  operatorType?: OperatorType;
  filters?: Filter[];
  dataType: "number" | "string" | "date" | "boolean" | "category";
}

enum FilterType {
  CONDITION = "CONDITION",
  CONDITIONGROUP = "CONDITIONGROUP",
}

enum JoinType {
  NONE = "NONE",
  OR = "OR",
  NOT = "NOT",
  AND = "AND",
}

enum OperatorType {
  EQUALS = "EQUALS",
  IS_NOT_EQUAL = "IS_NOT_EQUAL",
  LIKE = "LIKE",
  IS_NOT_LIKE = " IS_NOT_LIKE",
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN = "LESS_THAN",
  GREATER_THAN_EQUALS = "GREATER_THAN_EQUALS",
  LESS_THAN_EQUALS = "LESS_THAN_EQUALS",
  CONTAINS = "CONTAINS",
  NOT_CONTAINS = "NOT_CONTAINS",
  IS_NULL = "IS_NULL",
  IS_NOT_NULL = "IS_NOT_NULL",
  STARTS_WITH = "STARTS_WITH",
  ENDS_WITH = "ENDS_WITH",
  IN = "IN",
  IS_ANY_OF = "IS_ANY_OF",
  IS_NONE_OF = "IS_NONE_OF"
  //ILIKE = "ILIKE",
}

export interface QueryColumns {
  name: string;
  displayName?: string;
  formType?: any;
  dataType: "number" | "string" | "date" | "boolean" | "category";
  options?: { name: string; value: any }[];
  nullable?: boolean;
  referencedTableId?: any;
}
