import { ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Observable, Subject, Subscription, catchError, combineLatest, debounceTime, distinctUntilChanged, forkJoin, of, range, retry, takeUntil } from 'rxjs';
import { EntityService } from 'src/app/services/entity.service';
import { EntityGridService } from 'src/app/services/entity-grid.service';
import { Router } from '@angular/router';
import { Criteria } from 'src/app/services/utility/request-utility.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { AppUtilityService } from 'src/app/services/utility/app.utility.service';
import { LookupStorageService } from 'src/app/services/lookup-storage.service';
import { BroadcastService } from 'src/app/services/excel-communication.service';
import { NetworkService } from 'src/app/services/network.service';
import { SettingsService } from 'src/app/shared/settings/service/settings.service';
declare const Excel: any;
var changeDetectionEventInProgress = false;
var hasChangedRow: boolean = false;
@Component({
  selector: 'app-entity',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, NgxMatSelectSearchModule, FormsModule, ReactiveFormsModule, MatProgressSpinnerModule, MatIconModule, MatButtonModule],
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss']
})
export class EntityComponent {
  @ViewChild('searchInput') searchInput!: ElementRef;
  public version = environment.VERSION;
  public entityList: any = [];
  public modelList: any = [];
  public filteredEntityList: any = [];
  public filteredModelList: any = [];
  public loading: boolean = false;
  public pageNumber: number = 0;
  public defaultCodeSetting = "namecode";
  public headers: any;
  public entityId: any;
  public previousEntity: any;
  public currentPage: number = 0;
  public contentName: any = 'Loading Entity List...';
  public currentCriteria!: Criteria;
  public filter: any = [];
  public currentModel: any;
  public previousTableDatas: any;
  public selectedEntity: any;
  public modelId: any;
  public defaultModelId: any;
  public loadEntityData: any = [];
  public message: any;
  public btnTitle: any = 'Show Status';
  public icon_name: any = 'grid_on';
  public editRecords: any = [];
  public deletedRecords: any;
  // public domainAttributeIds: any = [];
  public readColumns: any = [];
  public autoColumns: any = [];
  public validation: boolean = false;
  public subscription$ = new Subject<void>();
  public environment = environment;
  public disablePublishButton: boolean = true;
  public tableName: any;
  public searchInputSubject = new Subject<string>();
  showFilter: boolean = false;
  entity: any;
  query: any;
  public selectedHeaders: any = [];
  public entityMetaData: any;
  public domainAttributesArray: any = [];
  public domainEntity: any;
  public totalElementLimit: any;
  visibleHeaders: any;
  isCodeNull: boolean = false;
  previousQuery: any;
  savedFilter: any = [];
  isUpdate = false;
  public onChangedEventHandler: any;
  public filterApiBatches: number = 0;
  public totalFilterApiBatch: any;
  public intervalId: any;
  public overLay: boolean = false;
  public currentDialog: any;
  public updatedData: any;
  public deletionRowRange: any;

  roles: any[] = [];
  sheetLoaded: any = [];
  existingSheets: any = [];
  roleChanged: boolean = false;

  columnRange: string = '';

  private publishWorker: Worker;
  rowCount: any;
  columnCount: any;
  publishContext: any;
  publishSheet: any;
  processSheet: any;
  currentSheetHeaders: any;
  public totalSelectedRowForDeletion: number = 0;
  public addedSheetsNames: string[] = [];
  proceed: boolean = true;
  reloadRequired = false;
  networkSubscription: Subscription | null = null;
  insertedRow: any = [];
  showSytsemAttribute = false;
  globalSettings: any;
  constructor(
    private entityService: EntityService,
    private entityGridService: EntityGridService,
    private cd: ChangeDetectorRef,
    private route: Router,
    private indexeddbService: LookupStorageService,
    private appUtilityService: AppUtilityService,
    private broadcastChannelService: BroadcastService,
    private networkService: NetworkService,
    private settingsService: SettingsService
  ) {
    this.addedSheetsNames = [];
    this.publishWorker = new Worker(
      new URL('src/app/web-worker/publish.worker.ts', import.meta.url),
      { type: 'module' }
    );
    this.publishWorker.addEventListener('message', this.publishWorkerMessage.bind(this));

  }

  async ngOnInit() {
    this.proceed = await this.broadcastChannelService.initializeBroadcastChannel();
    if (this.proceed) {
      await this.processSettings();
      this.officeReady();
      const { models } = await this.getAllModels();
      if (models) {
        this.modelList = models;
        this.filteredModelList = models;
        this.defaultModelId = this.filteredModelList[0].id
        if (!!this.defaultModelId) {
          this.handleModelChange(this.defaultModelId)
        }
      }

      // HANDLE THIS FOR FILTER THE ENTITY LIST
      this.searchInputSubject.pipe(
        debounceTime(500), // Adjust debounce time in milliseconds as needed
        distinctUntilChanged()
      ).subscribe((value: any) => {
        this.filteredEntityList = this.entityList.filter((item: any) => {
          return item.displayName
            .trim()
            .toLowerCase()
            .includes(value.trim().toLowerCase());
        });
      });
      this.totalElementLimit = environment.TOTALELEMENTS;
    }
  }


  async getSettings(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.settingsService.getGlobalSettings().subscribe({
        next: (results) => resolve(results),
        error: (err) => reject(err),
      });
    });
  }

  async processSettings() {
    const results = await this.getSettings();
    if (results?.settingsData) {
      this.showSytsemAttribute = results.settingsData.showSystem;
      this.globalSettings = results;
    } else {
      this.showSytsemAttribute = false;
      this.globalSettings = {
        settingsData: {
          displayFormat: this.defaultCodeSetting,
          showSytem: false,
          timezone: "null",
        },
        type: "global",
      };
    }
  }

  public officeReady() {
    Office.onReady(async (info) => {
      if (info.host === Office.HostType.Excel) {
        await Excel.run(async (context: any) => {
          const sheets = context.workbook.worksheets;
          const settings = context.workbook.settings;
          sheets.load("items/name, items/visibility");
          const tables = context.workbook.tables;
          tables.load('items/name');
          const previousRole = settings.getItemOrNullObject('roles');
          previousRole.load("value");
          await this.getCurrentTableName();
          const enablePublish = settings.getItemOrNullObject(this.tableName + 'changes');
          enablePublish.load("value");
          const currentMetaData = settings.getItemOrNullObject(this.tableName);
          currentMetaData.load("value");
          await context.sync();
          const data = !currentMetaData.isNullObject ? JSON.parse(currentMetaData.value) : { tableHeaders: this.headers };
          this.currentSheetHeaders = data.tableHeaders;
          if (tables.items.length > 0) {
            this.addedSheetsNames = tables.items.map((elm: any) => elm.name);
          }
          this.disablePublishButton = enablePublish.isNullObject ? true : enablePublish.value;
          const lookupSheets = await sheets?.items?.filter((el: any) => el?.name?.includes("Lookup_")).map((item: any) => item.name);
          if (lookupSheets?.length > 0) {
            await this.indexeddbService.ensureDbOpen();
            this.addMissingStores(lookupSheets);
          }

          this.sheetLoaded = {};
          tables.items
            .forEach((item: any) => {
              const tableName = item.name;
              if (tableName.includes("Entity_")) {
                this.onChangedEventHandler = item.onChanged.add(this.excelChangeEvent.bind(this));
              }
            })

          const visibleSheets = sheets.items.filter((el: any) => el.visibility === "Visible");
          this.existingSheets = visibleSheets;
          visibleSheets.forEach((item: any) => { this.sheetLoaded[item] = false })
          let token: any = localStorage.getItem('token');
          this.roles = JSON.parse(atob(token.split('.')[1])).realm_access?.roles;
          if (JSON.stringify(this.roles) !== JSON.stringify(previousRole.value) && !previousRole.isNullObject) {
            this.roleChanged = true
          }
          // Handle this event when selecting the rows or change the worksheet
          let entityMetaData: any;
          let permission: any;
          let tableName = "";
          Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, async (event: any) => {
            await this.getCurrentTableName();
            if (this.tableName) {
              this.getAllTableBySheet();
              if (tableName !== this.tableName) {
                entityMetaData = settings.getItemOrNullObject(this.tableName);
                entityMetaData.load("value");
                const enablePublish = settings.getItemOrNullObject(this.tableName + 'changes');
                enablePublish.load("value");
                await context.sync();
                this.disablePublishButton = enablePublish.isNullObject ? true : JSON.parse(enablePublish.value);
                const data = !entityMetaData.isNullObject ? JSON.parse(entityMetaData.value) : { tableHeaders: this.headers };
                this.currentSheetHeaders = data.tableHeaders;
                this.autoColumns = data?.autoColumn || [];
                permission = this.currentSheetHeaders.findIndex((el: any) => ['READ', 'DENY'].includes(el.permission));
                tableName = this.tableName;
                this.showOrHideSystemAtrributes();
              }
              this.cd.detectChanges();
              const selectedRanges = context.workbook.getSelectedRanges();
              selectedRanges.load("areas");
              await context.sync();
              let rowRanges: any = [];
              for (const area of selectedRanges.areas.items) {
                if (area.columnCount >= this.currentSheetHeaders.length - 6) {
                  const rowRange = this.getRangeDetails(area.address);
                  if (!!rowRange && rowRange !== '') {
                    const cellRange: any = rowRange && rowRange.includes(':') ? rowRange.split(":") : null;
                    const rangeArray = generateRange(+cellRange[0], +cellRange[1]);
                    rowRanges.push(...rangeArray);
                  }
                }
              }
              if (rowRanges.length > 0 && permission === -1) {
                this.deletionRowRange = rowRanges;
              } else {
                this.deletionRowRange = null;
              }
              this.cd.detectChanges();
            }
          });
          await context.sync();
        });
      }
    });
  }

  public async currentSelectingSheetEntity() {
    await Excel.run(async (context: any) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      let tableId: any = null;
      await this.getCurrentTableName();
      if (!!this.tableName) {
        tableId = this.tableName.split("_");
        tableId = tableId[1];
      }
      if (!!tableId) {
        this.selectedEntity = this.entityList.find((el: any) => el.id === +tableId);
        this.cd.detectChanges();
      }
      await context.sync();
    }).catch((err: any) => {
      console.log(err, 'err');
    })
  }

  public getRangeDetails(address: string): string {
    const match = address.match(/(\d+):(\d+)/);
    if (match) {
      return `${match[1]}:${match[2]}`;
    } else {
      // Extract rows and columns from the address
      const rangeParts = address.split("!");
      const range = rangeParts[1]; // E.g., "Sheet1!A1:B2"
      const [start, end] = range.split(":");
      if (start && end) {

        const startRow = start.replace(/[^0-9]/g, ''); // Extract row number from start cell
        const endRow = end.replace(/[^0-9]/g, ''); // Extract row number from end cell

        const startColumn = start.replace(/[0-9]/g, ''); // Extract column letters from start cell
        const endColumn = end.replace(/[0-9]/g, ''); // Extract column letters from end cell

        return `${startRow}:${endRow}`;
      }
      else {
        return ''
      }
    }
  }

  // Handle this function for getting the details from the office settings.
  public async getEntityMetaData() {
    if (!!this.entityId) {
      this.selectedEntity = this.entityList.find((item: any) => item.id === this.entityId);
      this.cd.detectChanges();
      await this.clearExcelCells(false, true, this.selectedEntity);
      this.showFilter = !!this.selectedEntity;
      this.excelRun()
    }
  }

  // HANDLE THIS FOR GET ALL MODELS
  public getAllModels(): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.entityService.getAllModels().subscribe(
        (models: any) => {
          resolve({
            models,
          });
        },
        (err: any) => {
          if (err.status === 401) {
            localStorage.removeItem('token');
            this.route.navigate(['/landing']);
          }
          this.loading = false;
          rejects({
            models: [],
          });
        }
      );
    });
  }
  // HANDLE THIS FOR GET THE ENTITY BY MODEL ID
  public handleModelChange(id: any) {
    this.loading = true;
    this.currentModel = this.modelList.find((el: any) => el.id === id);
    this.defaultModelId = this.currentModel?.id;
    this.searchInput?.nativeElement.value ? this.searchInput.nativeElement.value = '' : '';
    const sortType = this.currentModel?.orderType || 'asc';
    this.entityService.getEntityByModelId(id).subscribe(async (res: any) => {
      if (!!res) {
        this.entityList = res.sort((a: any, b: any) => {
          if (sortType === 'asc') {
            return a.displayName.localeCompare(b.displayName); // Ascending order for strings
        } else if (sortType === 'desc') {
            return b.displayName.localeCompare(a.displayName); // Descending order for strings
        } else {
          return a.order - b.order;
        }
        });
        this.filteredEntityList = this.entityList;
        this.cd.detectChanges();
        // this.getEntityFromSheet();
        this.loading = false;
      }
    }, err => {
      if (err.status === 401) {
        localStorage.removeItem('token');
        this.route.navigate(['/landing']);
      }
      this.loading = false;
    })
  }


  async addMissingStores(result: any) {
    for (const item of result) {
      try {
        await Excel.run(async (context: any) => {
          this.overLay = true;
          let sourceRange = context.workbook.worksheets.getItem(item).getUsedRange();
          sourceRange.load('values');
          await context.sync();
          const values = sourceRange.values;
          const lookupdata: any[] = [];

          for (const row of values) {
            const rowData: any = {
              id: row[1],
              code: row[0],
              name: null,
              key: row[0].toString()
            };

            if (row[0].toString().includes('{') && row[0].toString().includes('}')) {
              rowData.code = row[0].split('{')[0];
              rowData.name = row[0].substring(row[0].indexOf('{') + 1, row[0].indexOf('}'));
              rowData.key = rowData.code.toString();
            }
            lookupdata.push(rowData);
          }

          try {
            await this.indexeddbService.addStore(item, 'key');
          } catch (storeError) {
            console.error('Error creating store:', item, storeError);
            return; // Exit early if store creation fails
          }

          try {
            await this.indexeddbService.addBulkData(item, lookupdata);
          } catch (bulkError) {
            console.error('Error adding bulk data to:', item, bulkError);
            this.overLay = false;
          }
          this.overLay = false;
          this.indexeddbService.closeDb();
        });
      } catch (excelError) {
        this.overLay = false;
        console.error('Error processing Excel data for item:', item, excelError);
      }
    }
  }
  // public domainIdHeaders(headers: any) {
  //   this.domainAttributeIds = [];
  //   headers.forEach((item: any) => {
  //     if (item.formType === 'LOOKUP' && item.totalElements < this.totalElementLimit) {
  //       const value = { name: `${item.selectColumn}_id`, selectColumn: item.selectColumn, formType: 'LOOKUPID', systemAttribute: false, width: 100, id: item?.referencedTableId };
  //       this.domainAttributeIds.push(value);
  //     }
  //   })
  // }


  // HANDLE THIS FUNCTION WHEN SELECT THE ENTITY
  public async entitySelection(entity: any, criteria?: any, pageNumber?: number) {
    if (this.entity?.id !== entity.id) {
      let dialog: any;
      let args = 'continue';
      if (this.entity && this.loadEntityData.length != 0) {
        args = 'close';
        Office.context.ui.displayDialogAsync(
          `${environment.URL}/#/confirm`,
          { height: 30, width: 30 },
          (item) => {
            dialog = item.value;
            dialog.addEventHandler(
              Office.EventType.DialogMessageReceived,
              async (arg: any) => {
                // if (arg.message === 'send') {
                //   const data = { message: 'The content of active worksheet will be deleted. Do you want to continue?', yes: 'Yes', no: 'No' }
                //   dialog.messageChild(JSON.stringify(data));
                // } else {
                if (arg?.message === 'send') {
                  setTimeout(() => {
                    const data = { message: 'The content of active worksheet will be deleted. Do you want to continue?', yes: 'Yes', no: 'No' }
                    dialog.messageChild(JSON.stringify(data));
                  }, 800);
                }
                if (arg?.message === 'close') {
                  dialog.close();
                }
                if (arg?.message == 'continue') {
                  this.loadEntity(entity)
                }
              });
          });
      }
      if (args == 'continue') {
        this.loadEntity(entity)
      }
    }
  }

  public async loadEntity(entity: any) {
    await this.currentSheetMetaData(entity);
    this.isCodeNull = false
    this.showFilter = false
    this.message = '';
    this.loading = true;
    this.selectedEntity = entity;
    this.loadEntityData = [];
    this.cd.detectChanges();
    this.pageNumber = 0;
    this.subscription$.next();
    await this.clearExcelCells(false, false, entity);
    if (this.headers.length == 0) {
      this.headers = this.entityGridService.getGridHeaders(
        entity,
        true,
        this.defaultCodeSetting
      );
    }
    this.showFilter = true
    this.loading = false;
    this.contentName = '';
    this.cd.detectChanges();
    const modelName = { modelName: this.currentModel?.displayName };
    entity = { ...entity, ...modelName };
    this.entity = entity
  }

  public getEntityDataCounts(ids: any[]): Observable<any>[] {
    return ids.map(id =>
      this.entityService.getEntityDataCount(id, 0, 1, []));
  }

  public async openDialogBox(entity: any) {
    this.domainEntity = null;
    this.filterApiBatches = 0;
    this.totalFilterApiBatch = '...';
    this.currentDialog = null;
    // let currentSheetEntity = this.entityList.find((elm: any) => elm.id === this.entityId) || null;
    // currentSheetEntity = !!currentSheetEntity && (!!entity && entity?.id === currentSheetEntity?.id) ? currentSheetEntity : entity;
    // let domainBasedIds = this.headers.filter((el: any) => el.formType === 'LOOKUP');
    // if (domainBasedIds.length !== 0) {
    //   const uniqueReferencedTableIds = Array.from(
    //     new Set(domainBasedIds.map((elm: any) => elm.referencedTableId))
    //   );
    //   const data: any = new Promise((resolve) => { combineLatest([...this.getEntityDataCounts(uniqueReferencedTableIds)]).subscribe(res => resolve(res)) });
    //   this.domainEntity = uniqueReferencedTableIds.map((id: any, index: number) => {
    //     return { id: id, count: data.__zone_symbol__value[index] };
    //   });
    // }
    this.pageNumber = 0;
    const token = localStorage.getItem('token');
    Office.context.ui.displayDialogAsync(`${environment.URL}/#/filter/${entity?.groupId}/${entity?.id}/${token}`, { height: 80, width: 90 },
      (item) => {
        const dialog = item.value;
        this.currentDialog = dialog;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (arg: any) => {
          if (arg?.message === 'send') {
            const data = { query: this.query, headers: this.selectedHeaders, filters: this.filter, savedFilter: this.savedFilter, isUpdate: this.isUpdate }
            dialog.messageChild(JSON.stringify(data));
          }
          else {
            // dialog.close();
            let item: any;
            if (arg?.message !== 'close') { item = JSON.parse(decodeURIComponent(arg?.message)); }
            const totalDomain = +item?.data?.totalDomainEntity;
            if (!!item?.data) {
              this.domainEntity = item?.data?.domainEntity;
            }
            if ([arg?.message, item?.message].includes('token-expired')) {
              dialog.close();
              localStorage.removeItem('token');
              this.route.navigate(['/landing']);
            }
            if (item?.type === 'invalid-version') {
              dialog.close();
              setTimeout(() => {
                this.handleMismatchVersionDialog(item.message);
              }, 500);
            }
            if (item?.type === 'progress') {
              this.overLay = true;
              this.intervalId = setInterval(() => {
                dialog.messageChild(JSON.stringify({ currentBatch: this.filterApiBatches, totalBatch: this.totalFilterApiBatch }));
                this.contentName = `Loading batch ${this.filterApiBatches} of ${this.totalFilterApiBatch}`,
                  this.cd.detectChanges();
                if (this.filterApiBatches === this.totalFilterApiBatch) {
                  clearInterval(this.intervalId);
                  this.cd.detectChanges();
                }
              }, 10);
            }
            else {
              this.overLay = false;
              this.cd.detectChanges();
            }
            if (arg?.message == 'close') {
              this.query = this.previousQuery
              this.isUpdate = false
              dialog.close();
              this.cd.detectChanges();
            }
            if (arg?.message !== 'close' && arg?.message !== 'send') {
              if (this.loadEntityData.length !== 0) {
                setTimeout(() => {
                  Office.context.ui.displayDialogAsync(
                    `${environment.URL}/#/confirm`,
                    { height: 30, width: 30 },
                    (item) => {
                      const dialog = item.value;
                      dialog.addEventHandler(
                        Office.EventType.DialogMessageReceived,
                        async (args: any) => {
                          if (args.message == 'continue') {
                            this.loading = true;
                            await this.clearExcelCells(false, true, entity);
                            this.loadData(entity, arg)
                          }
                        });
                    });
                }, 1000);
              }
              else {
                this.loading = true;
                await this.clearExcelCells(true, false, entity);
                this.loadData(entity, arg)
              }
            }
          }
        }
        )
      })
  }


  public async loadData(entity: any, arg: any) {
    this.reloadRequired = false;
    this.contentName = 'Loading Entity Data...';
    this.loadEntityData = [];
    this.domainAttributesArray = [];
    this.loading = true;
    this.cd.detectChanges();
    const attribute = JSON.parse(decodeURIComponent(arg?.message));
    this.headers = attribute.data.headers;
    this.currentSheetHeaders = attribute?.data?.headers;
    this.selectedHeaders = attribute.data.selectedAttibutes;
    this.domainAttributesArray = attribute.data.attributeNamesToReplaceCodeWithId?.filter((el: any) => el.count >= this.totalElementLimit);
    this.addHeadersIfNotExists();
    this.visibleHeaders = [...this.headers];
    this.filter = attribute.data.filters;
    this.query = attribute.data.query;
    this.previousQuery = attribute.data.query;
    const lookupDataLength = this.headers.filter((el: any) => el.formType === 'LOOKUP').length;
    const validationStatusIndex = this.headers.findIndex((item: any) => item.column === 'validationstatus');
    const idIndex = this.headers.findIndex((item: any) => item.column === 'id');
    const inputStatusIndex = this.headers.findIndex((item: any) => item.column === 'inputstatus');
    const validationMessageIndex = this.headers.findIndex((item: any) => item.column === 'validationmessage');
    const inputMessageIndex = this.headers.findIndex((item: any) => item.column === 'inputmessage');
    const changedIndex = this.headers.findIndex((item: any) => item.column === 'changedIndices');

    // Ensure all required columns exist
    if (validationStatusIndex !== -1 && idIndex !== -1 && inputStatusIndex !== -1) {
      // Remove the objects from their original positions
      const validationStatusItem = this.headers.splice(validationStatusIndex, 1)[0];
      const idItem = this.headers.splice(idIndex - (idIndex > validationStatusIndex ? 1 : 0), 1)[0];
      const inputStatusItem = this.headers.splice(inputStatusIndex - (inputStatusIndex > validationStatusIndex ? 1 : 0) - (inputStatusIndex > idIndex ? 1 : 0), 1)[0];
      const validationMessageItem = this.headers.splice(validationMessageIndex - (validationMessageIndex > validationStatusIndex ? 1 : 0) - (validationMessageIndex > idIndex ? 1 : 0) - (validationMessageIndex > inputStatusIndex ? 1 : 0), 1)[0];
      const inputMessageItem = this.headers.splice(inputMessageIndex - (inputMessageIndex > validationStatusIndex ? 1 : 0) - (inputMessageIndex > idIndex ? 1 : 0) - (inputMessageIndex > inputStatusIndex ? 1 : 0) - (inputMessageIndex > validationMessageIndex ? 1 : 0), 1)[0];
      const changedItem = this.headers.splice(changedIndex - (changedIndex > validationStatusIndex ? 1 : 0) - (changedIndex > idIndex ? 1 : 0) - (changedIndex > inputStatusIndex ? 1 : 0) - (changedIndex > validationMessageIndex ? 1 : 0) - (changedIndex > inputMessageIndex ? 1 : 0), 1)[0];
      // Add them back to the beginning of the array in the desired order
      this.headers.unshift(idItem, validationStatusItem, validationMessageItem, inputStatusItem, inputMessageItem, changedItem);
    }
    await this.indexeddbService.ensureDbOpen();
    await this.getAllDomainApi(lookupDataLength);
    this.entityService.clearCache();
    if (!this.reloadRequired) {
      this.excelRun();
    }
  }

  public addHeadersIfNotExists(): void {
    const newHeaders = [
      {
        dataType: "number",
        name: "__ROW_IDENTIFIER",
        formType: "FREE_FORM",
        column: "is_valid",
        options: undefined,
        isFilterable: true,
        referencedTableId: null,
        selectColumn: '__ROW_IDENTIFIER',
        systemAttribute: false,
        width: 100,
        autoColumn: true
      },
      {
        dataType: "string",
        column: 'inputstatus',
        name: "Input Status",
        formType: "FREE_FORM",
        options: undefined,
        isFilterable: true,
        referencedTableId: null,
        selectColumn: 'inputstatus',
        systemAttribute: false,
        width: 100,
        autoColumn: true
      },
      {
        dataType: "string",
        column: 'inputmessage',
        name: "Input Message",
        formType: "FREE_FORM",
        options: undefined,
        isFilterable: true,
        referencedTableId: null,
        selectColumn: 'inputmessage',
        systemAttribute: false,
        width: 100,
        autoColumn: true
      },
      {
        dataType: "string",
        name: "Validation Message",
        formType: "FREE_FORM",
        column: "validationmessage",
        options: undefined,
        isFilterable: true,
        referencedTableId: null,
        selectColumn: 'validationmessage',
        systemAttribute: false,
        width: 100,
        autoColumn: true
      },
      {
        dataType: "string",
        name: "Changed Indices",
        formType: "FREE_FORM",
        column: "changedIndices",
        options: undefined,
        isFilterable: true,
        referencedTableId: null,
        selectColumn: 'changedIndices',
        systemAttribute: false,
        width: 100,
        autoColumn: true
      }
    ];
    // Iterate over the new headers
    newHeaders.forEach(newHeader => {
      // Check if a header with the same selectColumn already exists
      const exists = this.headers.some((header: any) => header.selectColumn === newHeader.selectColumn);

      if (!exists) {
        // Add the new header if it doesn't already exist
        this.headers.push(newHeader);
      }
    });
  }

  public loadDataByEntityId(entity: any, criteria: any): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.entityService.getEntitesByIds(entity?.id, criteria).pipe(
        takeUntil(this.subscription$),
        retry(1), // Retry the request up to 3 times before failing
        catchError((err) => {
          console.log(err);
          if (err.status === 401) {
            localStorage.removeItem('token');
            this.route.navigate(['/landing']);
          }
          if (err?.error?.errorCode === "VEX-400") {
            this.currentDialog.close();
            setTimeout(() => {
              this.handleMismatchVersionDialog(err?.error?.message);
            }, 500);
          }
          this.loading = false;
          this.overLay = false
          return of({ entityData: [] }); // Return an empty entityData array in case of an error
        })
      ).subscribe(
        (entityData: any) => {
          resolve({
            entityData,
          });
        },
        (err: any) => {
          if (err.status === 401) {
            localStorage.removeItem('token');
            this.route.navigate(['/landing']);
          }
          // this.loading = false;
          resolve({
            entityData: [],
          });
        }
      );
    });
  }

  public formatingTableRowData(data: any, sheet: any, startRowIndex: any) {
    const domainTotalElement = this.headers.filter((el: any) => el.totalElements >= this.totalElementLimit);
    const domainTotalElementNames = domainTotalElement?.map((att: any) => `${att.selectColumn}_id`);
    this.headers = this.headers.filter((elm: any) => !domainTotalElementNames.includes(elm.name));

    // let domainAttributeIndex: any = [];
    const headerPermission = this.headers.find((elm: any) => ['READ', 'DENY'].includes(elm.permission));

    return data.map((el: any, index: number) => {
      if ([undefined, 'FALSE', false].includes(el.validationstatus)) {
        el = { ...el, validationstatus: 'Validation Failed' };
      } else if (['isValid', 'TRUE', true, 'nan', null].includes(el.validationstatus)) {
        el = { ...el, validationstatus: 'Validation Succeeded' };
      }
      if (['READ', 'DENY'].includes(el.permission)) {
        this.readColumns.push(index + startRowIndex);
        const fullRow = sheet.getRangeByIndexes(index + startRowIndex, 0, 1, this?.headers?.length - 1);
        fullRow.format.fill.color = "#c5c5c5";
      }
      return this.headers.map((header: any, headerIndex: number) => {
        const columnLetter = ExcelHelper.getColumnLetter(headerIndex);
        if (header.dataType == 'date' && !!el[header.column]) {
          el[header.column] = moment(el[header.column]).parseZone().format("MM/DD/YYYY")
        }

        if (header.selectColumn === '__ROW_IDENTIFIER') {
          const rowIndexFormula = `=ROW()`;
          return rowIndexFormula;
        }
        if (header.selectColumn === 'inputstatus') {
          const inputStatus = ['', null, undefined].includes(el?.validationstatus) ? 'Error' : ['DENY', 'READ'].includes(el?.permission) ? 'Read Only' : 'Unchanged';
          return inputStatus;
        }
        if (header.formType === "LOOKUP") {
          // const val: any = { columnLetter: columnLetter, name: header.selectColumn };
          // domainAttributeIndex.push(val);
          const name = el[`${header.selectColumn}_name`];
          const code = el[`${header.selectColumn}_code`];
          const value = header.totalElements > this.totalElementLimit ? code : !!name && !!code ? `${code}{${name}}` : !name && code ? `${code}` : name;
          return value
        }
        // else if (header.formType === "LOOKUPID") {
        //   const letterIndex = index + 2;
        //   const attIndex = domainAttributeIndex.find((el: any) => el.name === header.selectColumn);
        //   const value = `=IFERROR(INDEX(Lookup_${header.id}!B:B, MATCH(${attIndex?.columnLetter}${letterIndex}, Lookup_${header.id}!A:A, 0)), "")`;
        //   return value
        // }
        else {
          return el[header?.selectColumn]
        }
      });
    });
  }

  public async refreshEntityRecords() {
    this.reloadRequired = false;
    if (this.roleChanged && !this.sheetLoaded[this.entity?.displayName.trim().replace(/\s+/g, "")] && this.existingSheets.includes(this.entity?.displayName)) {
      this.checkSheetPermission()
    }
    else {
      this.loading = true;
      this.overLay = true;
      this.icon_name = 'grid_on';
      this.btnTitle = 'Show Status';
      this.contentName = 'Loading domain-based attributes...';
      this.totalFilterApiBatch = '...';
      this.filterApiBatches = 0;
      this.loadEntityData = [];
      this.domainAttributesArray = [];
      this.pageNumber = 0;
      this.cd.detectChanges();
      Office.context.ui.displayDialogAsync(
        `${environment.URL}/#/loader`, { height: 50, width: 60 },
        (asyncResult: any) => {
          const dialog = asyncResult.value;
          this.currentDialog = dialog;
          dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (arg: any) => {
            if (arg?.message === 'start') {
              this.intervalId = setInterval(() => {
                dialog.messageChild(JSON.stringify(this.contentName));
                this.cd.detectChanges();
                // if ((this.filterApiBatches !== undefined && this.totalFilterApiBatch !== undefined) && this.filterApiBatches === this.totalFilterApiBatch) {
                //   clearInterval(this.intervalId);
                //   this.cd.detectChanges();
                // }
              }, 10);
            }
          })
        });
      const lookupDataLength = this.headers.filter((el: any) => el.formType === 'LOOKUP').length;
      this.entityService.clearCache();
      await this.indexeddbService.ensureDbOpen();
      await this.getAllDomainApi(lookupDataLength);
      this.entityService.clearCache();
      this.getEntityMetaData();
    }
  }

  public async refreshExcelData(entity: any) {
    if (hasChangedRow) {
      Office.context.ui.displayDialogAsync(
        `${environment.URL}/#/confirm`,
        { height: 30, width: 40 },
        (item) => {
          const dialog = item.value;
          dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            async (arg: any) => {
              // if (arg.message === 'send') {
              //   const data = { message: 'The content of active worksheet will be deleted. Do you want to continue?', yes: 'Yes', no: 'No' }
              //   dialog.messageChild(JSON.stringify(data));
              // } else {
              if (arg?.message === 'send') {
                setTimeout(() => {
                  const data = { message: 'Some changes have not been published. If you continue, all unpublished changes will be lost. Do you want to continue?', yes: 'Yes', no: 'No' }
                  dialog.messageChild(JSON.stringify(data));
                }, 800);
              }
              if (arg?.message == 'close') {
                dialog.close();
              }
              if (arg?.message == 'continue') {
                dialog.close();
                hasChangedRow = false;
                setTimeout(() => {
                  this.refreshEntityRecords();
                }, 500);
              }
            });
        });
    }
    else {
      this.refreshEntityRecords();
    }
  }

  public async publish() {
    if (this.roleChanged && !this.sheetLoaded[this.entity?.displayName.trim().replace(/\s+/g, "")] && this.existingSheets.includes(this.entity?.displayName)) {
      this.checkSheetPermission()
    }
    else {
      Excel.run(async (context: any) => {
        var sheet = context.workbook.worksheets.getActiveWorksheet();
        const tableName = await this.getCurrentTableName();
        const table = sheet.tables.getItem(this.tableName);
        const headerRowRange = table.getHeaderRowRange();
        const settings = context.workbook.settings;
        const entityMetaData = settings.getItem(this.tableName);
        entityMetaData.load("value");
        headerRowRange.load("values");
        await context.sync();
        const data = JSON.parse(entityMetaData.value);
        const codeColumn = data?.tableHeaders?.find((el: any) => el.column === 'code');
        if (!headerRowRange.values[0].includes(codeColumn?.name)) {
          Office.context.ui.displayDialogAsync(
            `${environment.URL}/#/missing/column`, { height: 30, width: 30 },
            (asyncResult: any) => {
              const dialog = asyncResult.value;
              dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
                // if (arg.message === 'send') {
                //   const data = { message: "Critical columns missing : 'Code'. Detaching from worksheet", yes: 'Close' }
                //   dialog.messageChild(JSON.stringify(data));
                // } else {
                dialog.close();
                // }
              })
            }
          );
        }
        else {
          Office.context.ui.displayDialogAsync(
            `${environment.URL}/#/loader`, { height: 50, width: 60 },
            (asyncResult: any) => {
              const dialog = asyncResult.value;
              this.currentDialog = dialog;
              dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (arg: any) => {
                if (arg?.message === 'start') {
                  this.intervalId = setInterval(() => {
                    dialog.messageChild(JSON.stringify(this.contentName));
                    this.cd.detectChanges();
                    // if ((this.filterApiBatches !== undefined && this.totalFilterApiBatch !== undefined) && this.filterApiBatches === this.totalFilterApiBatch) {
                    //   clearInterval(this.intervalId);
                    //   this.cd.detectChanges();
                    // }
                  }, 10);
                  const data = JSON.parse(entityMetaData.value);
                  this.entityId = data?.entity_id
                  this.selectedEntity = this.entityList.find((el: any) => el.id === this.entityId);
                  this.domainAttributesArray = data?.attributeNamesToReplaceCodeWithId;
                  this.headers = data?.tableHeaders;
                  this.readColumns = data?.readColumns;
                  this.autoColumns = data?.autoColumns;
                  this.publishRecords()
                }
              })
            });
        }
        return context.sync();
      })
    }
  }




  // Handle this function to add the data in excel sheet
  public async excelRun(data?: any, criteria?: any) {
    await Excel.run(async (context: any) => {
      this.addPublishStatus(context, true);
      let columnCount: any;
      let currentSheetData: any = [];
      // Get the active worksheet
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const pageSize = environment.PAGESIZE;
      // initial column data validation
      this.addPreHeaderDataValidation(sheet, this.headers, context);

      columnCount = this.headers.length;
      let headerRange = sheet.getRangeByIndexes(0, 0, 1, columnCount);
      // let range = sheet.getRangeByIndexes(0, 0, rowCount - (rowCount - 1), columnCount);
      // Freeze the headers
      sheet.freezePanes.freezeRows(1);
      const rangeA1 = sheet.getRange("C2");
      rangeA1.select();

      // Set headers dynamically
      const headers = this.headers.map((header: any) => header.name);
      headerRange.values = [headers];

      let lastId;
      for (let currentPage = 0, totalPages = 1; currentPage < totalPages; currentPage++) {
        if (this.reloadRequired == true) {
          break;
        }
        let myCriteria = {
          filters: this.filter,
          sorters: [{ direction: "ASC", property: "id" }],
          pager: {
            pageNumber: currentPage,
            pageSize: environment.PAGESIZE,
            // Todo: need to uncomment once API Issue is resolved
            ...(currentPage !== 0) ? {
              overRideOffset: true,
              greaterThan: true,
              id: lastId,
            } : {}
          }
        }
        const customCriteria = !criteria ? myCriteria : criteria;
        const { entityData } = await this.loadDataByEntityId(this.selectedEntity, customCriteria);
        if (entityData?.content?.length > 0) {
          this.filterApiBatches = currentPage + 1;
          this.totalFilterApiBatch = entityData.totalPages;
          this.contentName = `Loading batch ${this.filterApiBatches} of ${this.totalFilterApiBatch}`,
            this.cd.detectChanges();
          const item: any = entityData?.content || null;
          currentSheetData.push(...item);
          if (!item) {
            return;
          }
          lastId = item[item.length - 1]['id'] || null;
          const startRowIndex = currentPage * pageSize + 1;
          const tableData = await this.formatingTableRowData(item, sheet, startRowIndex);
          this.cd.detectChanges();
          if (tableData.length > 0) {
            columnCount = this.headers.length;
            const startRowIndex = currentPage * pageSize + 1;
            const tableRange = sheet.getRangeByIndexes(startRowIndex, 0, tableData.length, columnCount);
            tableRange.values = tableData;
            totalPages = entityData.totalPages;
            // await context.sync();
          }
        }
        else {
          await this.manageRowIdentifierRow(sheet);
          this.loading = false;
          this.overLay = false;
          this.cd.detectChanges();
        }
      }
      // await context.sync();
      const usedRange = sheet.getUsedRange();
      usedRange.load('rowCount, columnCount'); // Load rowCount and columnCount properties

      await context.sync();

      const startRow = 0;
      const startColumn = 0;
      const rowCount = usedRange.rowCount;
      const columnCounts = usedRange.columnCount;

      // Get the range of the data to be converted to a table
      const dataRange = sheet.getRangeByIndexes(startRow, startColumn, rowCount, columnCounts);

      // Convert the range to a table
      const table = sheet.tables.add(dataRange, true /* hasHeaders */);
      this.tableName = `Entity_${this.selectedEntity?.id}`;
      table.name = this.tableName;
      const settings = context.workbook.settings;
      settings.add(`${this.tableName}_error_status`, JSON.stringify({ errorRows: [] }));
      this.addPostDataValidation(sheet, this.headers, context, data);
      this.sheetFormating(sheet);
      currentSheetData = currentSheetData.map((el: any) => { return el.permission })
      this.addEntityMetaData(this.filter, this.selectedEntity, currentSheetData);
      this.getAllTableBySheet()
      this.onChangedEventHandler = table.onChanged.add(this.excelChangeEvent.bind(this));
      this.sheetFormating(sheet);
      await this.readOnlyColumns(sheet, this.headers, currentSheetData);
      if (!sheet) {
        return;
      }
      if (!!this.currentDialog) {
        this.overLay = false;
        this.currentDialog.close();
      }
      if (!!this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.entityId = this.selectedEntity?.id;
      this.loading = false;
      this.overLay = false
      this.sheetLoaded[this.entity?.displayName.trim().replace(/\s+/g, "")] = true;
      this.message = {
        message: `Record loaded successfully in "${this.selectedEntity?.displayName}"`,
        type: 'success'
      };
      this.unsubscribeNetwork();
      this.reloadRequired = false;
      this.contentName = '';
      this.indexeddbService.closeDb();
      this.cd.detectChanges();
      this.clearMessage();
    })
      .catch((error: any) => {
        console.error("Error adding data to rows:", error);
        this.loading = false;
      });
  }
  close() {
    this.message = '';
    this.cd.detectChanges();
  }

  public sheetFormating(sheet: any) {
    const range = sheet.getUsedRange();
    range.format.autofitColumns();
    range.format.autofitRows();
    range.format.horizontalAlignment = "Left";
    const unLockColumns = sheet.getRange("A1:XFD1048576");
    if (unLockColumns) {
      unLockColumns.format.protection.locked = false;
    }
    this.headers.forEach((item: any, index: any) => {
      const columnLetter = ExcelHelper.getColumnLetter(index);
      if (!!columnLetter && ['id', 'validation status', '__row_identifier', 'input status', 'validation message', 'input message', 'changed indices'].includes(item.name.toLowerCase()) || item.name.endsWith('_id') || (item.systemAttribute && !this.showSytsemAttribute)) {
        sheet.getRange(`${columnLetter}:${columnLetter}`).columnHidden = true;
      }
      return
    });
  }

  public async readOnlyColumns(sheet: any, header: any, record: any) {
    await header.forEach((item: any, index: number) => {
      const columnLetter = ExcelHelper.getColumnLetter(index);
      const lastIndex = record.length + 1;
      let lockColumn = sheet.getRange(`${columnLetter}:${columnLetter}`);
      if (['READ', 'DENY'].includes(item.permission)) {
        this.readColumns.push(`${columnLetter}:${columnLetter}`);
        if (lockColumn) {
          lockColumn.format.protection.locked = true;
          lockColumn.format.fill.color = "#c5c5c5";
        }
      }
    })
    // if (readonly && environment.URL === 'https://excel-addin.spriced.nrp.simadvisory.com') {
    //   sheet.protection.protect({
    //     allowAutoFilter: true,
    //     allowDeleteColumns: true,
    //     allowDeleteRows: true,
    //     allowFormatCells: true
    //   }, "SIM8Eight#");
    // }
  }

  public async updateCellWithStatus(sheet: any, record: any, context: any, type?: any) {
    let messages: any = [];
    if (![null, undefined].includes(record.ruleValidations))
    // if record.rulevalidation are not empty
    {
      for (let i = 0; i < record.ruleValidations.length; i++) {
        let ruleVal = record.ruleValidations[i];
        const rowNumber = ruleVal?.output.__ROW_IDENTIFIER;
        let inputValue = sheet.getRange(`D${rowNumber}`);
        let validationInput = sheet.getRange(`B${rowNumber}`);
        let validationMessageField = sheet.getRange(`C${rowNumber}`);
        for (let validationIndex = 0; validationIndex < ruleVal.ruleResults.length; validationIndex++) {
          const failedRule = ruleVal.ruleResults[validationIndex];
          messages.push(failedRule.message);
          if (!failedRule.success) {
            this.validation = true;
            validationInput.values = [['Validation Failed']];
            inputValue.values = [['Error']];
            const range = sheet.getRange(`B${rowNumber}`);
            range.getUsedRange().format.font.color = "#c5261f";
            this.validation = false;
          }
          const inputStatus = this.readColumns.length > 0 ? 'Read Only' : 'Unchanged';
          if (type === 'changed') {
            inputValue.values = [[inputStatus]];
          }
        }
        const concatenatedString = messages.join('\n');
        // find the particular row and then adding the message
        validationMessageField.values = [[concatenatedString]];
        messages = [];
        if (ruleVal.succes) {
          validationInput.values = [["Validation Succeeded"]];
        }
      }
    }
    else {
      // if record.rulevalidation are empty
      if (record?.result?.length > 0) {
        for (let i = 0; i < record.result.length; i++) {
          let ruleVal = record.result[i];
          const rowNumber = ruleVal?.__ROW_IDENTIFIER;
          let inputValue = sheet.getRange(`D${rowNumber}`);
          let validationInput = sheet.getRange(`B${rowNumber}`);
          const inputValues = this.readColumns.length > 0 ? ['Read Only'] : ['Unchanged'];
          const validationValues = ['Validation Succeeded'];
          if (type === 'changed') {
            inputValue.values = [inputValues];
          }
          validationInput.values = [validationValues];
        }
      }
    }
    await context.sync();
  }

  public removeFilters() {
    if (this.filter.length > 0) {
      const myCriteria = {
        filters: this.filter,
        sorters: [{ direction: "DESC", property: "id" }],
        pager: { pageNumber: 0, pageSize: environment.PAGESIZE }
      }
      this.excelRun();
    }
  }

  // Handle this function to add the validation in columns
  private addPostDataValidation(sheet: any, headers: any, context: any, record: any) {
    headers.forEach(async (el: any, index: number) => {
      const columnLetter = ExcelHelper.getColumnLetter(index);

      if (el.name === "Code" && el.isAuto == false && !!sheet.tables) {
        let range = sheet.tables.getItem(this.tableName).columns.getItem(el.name).getDataBodyRange()
        range.dataValidation.ignoreBlanks = false
        range.dataValidation.rule = {
          custom: {
            formula: `=AND(COUNTIF(${columnLetter}:${columnLetter}, ${columnLetter}2)=1, OR(LEN(${columnLetter}2)>0,NOT(ISBLANK(${columnLetter}2))))`
          }
        };
        range.dataValidation.errorAlert = {
          message: "No duplicates or null allowed for code",
          showAlert: true, // The default is 'true'.
          style: Excel.DataValidationAlertStyle.stop,
          title: "Duplicate value entered"
        };
      }
      if (el.formType === "FREE_FORM") {
        switch (el.dataType) {
          // case 'date':
          //   if (!!sheet.tables) {
          //     let range = sheet.tables.getItem(this.tableName).columns.getItem(el.name).getDataBodyRange()
          //     range.format.numberFormat = el.dateFormat;
          //     range.dataValidation.rule = {
          //       date: {
          //         formula1: "01/01/1900",
          //         formula2: "12/31/9999",
          //         operator: Excel.DataValidationOperator.between
          //       }
          //     };
          //     range.dataValidation.errorAlert = {
          //       message: `Sorry, enter a correct date format. e.g ${el.dateFormat}`,
          //       showAlert: true,
          //       style: Excel.DataValidationAlertStyle.stop,
          //       title: "Data Type Mismatch"
          //     };
          //   }
          //   break;
          // case 'number':
          //   if (!!sheet.tables) {
          //     let range = sheet.tables.getItem(this.tableName).columns.getItem(el.name).getDataBodyRange()
          //     // range.numberFormat = [['0.########']];
          //     range.dataValidation.rule = {
          //       custom: {
          //         formula: `=AND(ISNUMBER(${columnLetter}2), OR(${columnLetter}2 >= 0, ${columnLetter}2 < 0))`
          //       }
          //     };
          //     range.dataValidation.errorAlert = {
          //       message: "Sorry, only numbers are allowed",
          //       showAlert: true, // The default is 'true'.
          //       style: Excel.DataValidationAlertStyle.stop,
          //       title: "Data Type Mismatch"
          //     };
          //   }
          //   break;
          default:
            break;
        }
      }
    })
  }

  addPreHeaderDataValidation(sheet: any, headers: any, context: any) {
    headers.forEach(async (el: any, index: number) => {
      const columnLetter = ExcelHelper.getColumnLetter(index);
      let columnRange = sheet.getRange(`${columnLetter}:${columnLetter}`);
      // const domainElementscount = this.headers.find((domAtt: any) => domAtt.name === el.selectColumn)?.elements;
      if (el.formType === "LOOKUP" && el.totalElements < this.totalElementLimit) {
        const showDropdown: boolean = el?.permission !== 'READ';
        let sourceRange = context.workbook.worksheets.getItem('Lookup_' + el.referencedTableId).getRange('A:A');

        columnRange.dataValidation.rule = {
          list: {
            source: sourceRange,
            formulaHidden: false,
            inCellDropDown: showDropdown,
          }
        };
        columnRange.dataValidation.errorAlert = { showAlert: false, };
      }

      if (el.formType === "LOOKUP" && el.totalElements > this.totalElementLimit) {
        columnRange.numberFormat = [['@']];
      }
      if (el.systemAttribute || el.autoColumn || el.autoGenerated) {
        this.autoColumns.push(`${columnLetter}:${columnLetter}`)
        columnRange.format.fill.color = "#c5c5c5";
      }
      else if (el.formType === "FREE_FORM") {
        switch (el.dataType) {
          case 'string':
            columnRange.numberFormat = [['@']];
            break;
          // case 'number':
          //   // columnRange.numberFormat = [['0.########']];
          //   columnRange.dataValidation.rule = {
          //     custom: {
          //       formula: `=AND(ISNUMBER(${columnLetter}2), OR(${columnLetter}2 >= 0, ${columnLetter}2 < 0))`
          //     }
          //   };
          //   columnRange.dataValidation.errorAlert = {
          //     message: "Sorry, only numbers are allowed",
          //     showAlert: true, // The default is 'true'.
          //     style: Excel.DataValidationAlertStyle.stop,
          //     title: "Data Type Mismatch"
          //   };
          //   break;

          default:
            break;
        }
      }
    })
  }

  public async showHideStatus(title: any) {
    const status = title === 'Hide Status';
    [this.btnTitle, this.icon_name] = status ? ['Show Status', 'grid_on'] : ['Hide Status', 'grid_off'];
    this.cd.detectChanges();
    await Excel.run(async (context: any) => {
      //   // Get the active worksheet
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      //   const headerVal = this.headers.map((el: any) => { return el.name.toLowerCase() });
      //   headerVal.forEach((item: any, index: any) => {
      //     const columnLetter = ExcelHelper.getColumnLetter(index);
      //     if (!!columnLetter && ['validation status', 'input status', 'validation message', 'input message'].includes(item)) {
      // sheet.getRange(`${columnLetter}:${columnLetter}`).columnHidden = status;
      //   }
      //   return
      // })
      sheet.getRange('B:E').columnHidden = status;
      await context.sync();

    });
  }

  public async updateValidationStatus(item: any) {
    await Excel.run(async (context: any) => {
      let sheet = context.workbook.worksheets.getActiveWorksheet();

      await item.forEach((item: any) => {
        const cellAddress = `D${item.__ROW_IDENTIFIER}`;
        let inputValue = sheet.getRange(cellAddress);
        const inputStatus = 'Error';
        inputValue.values = [[inputStatus]];
        // const comments = sheet.comments;
        // comments.add(cellAddress, item.message);
        // const range = sheet.getRange("A" + item.__ROW_IDENTIFIER + ":XFD" + item.__ROW_IDENTIFIER);
        // range.getUsedRange().format.fill.color = "#ecc0be";
      })
      await context.sync();
    });
  }

  public clearFormatByRange(sheet: any, range: any) {
    range.format.fill.clear();

    this.readColumns.forEach((columnRange: any) => {
      const fullRow = sheet.getRangeByIndexes(columnRange, 0, 1, this?.headers?.length - 1);
      fullRow.format.fill.color = "#c5c5c5";
      // const lockColumn = sheet.getRange(columnRange);
      // lockColumn.format.fill.color = "#c5c5c5";
    })
    this.autoColumns.forEach((columnRange: any) => {
      const lockColumn = sheet.getRange(columnRange);
      lockColumn.format.fill.color = "#c5c5c5";
    })
  }

  public async setAllColors(sheet: any, context: any) {
    // Iterate through each item in readColumns and apply a grey color for readonly
    this.readColumns.forEach((item: string | number) => {
      if (typeof item === 'string') {
        // Handle the case where item is a range string
        const range = sheet.getRange(item);
        range.format.fill.color = "#c5c5c5";
      } else if (typeof item === 'number') {
        // Handle the case where item is a row index
        const fullRow = sheet.getRangeByIndexes(item, 0, 1, this?.headers?.length - 1);
        fullRow.format.fill.color = "#c5c5c5";
      }
    });
    this.autoColumns.forEach((columnRange: any) => {
      const lockColumn = sheet.getRange(columnRange);
      lockColumn.format.fill.color = "#c5c5c5";
    })
    await context.sync();
  }

  public async updateDomainAttributeFields(records: any) {
    const array = await Promise.all(records.map(async (item: any) => {
      const publishItem: any = {};

      // Iterate over the keys of the object
      for (const headerItem of this.visibleHeaders) {
        const key = headerItem.selectColumn;
        const value = item[headerItem.selectColumn];

        if (!!value && headerItem.dataType === 'date') {
          const milliseconds = (value - 25569) * 86400 * 1000;
          var m = moment(milliseconds).utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format()
          publishItem[key] = m;
        }
        else if (headerItem && headerItem.formType === 'LOOKUP') {
          let attribute: any = null;
          if (!!value && value !== '#N/A' && headerItem.totalElements < this.totalElementLimit) {
            attribute = await this.getDomainId(value, headerItem.referencedTableId);
          }
          publishItem[key] = headerItem.totalElements > this.totalElementLimit ? value : !!attribute ? attribute.id : "";
          publishItem[`${key}_code`] = !!attribute ? attribute.code : null;
          publishItem[`${key}_name`] = !!attribute ? attribute.name : null;
        }
        else if (value === '#N/A') {
          publishItem[key] = null;
        }
        else {
          publishItem[key] = value;
        }
      }
      delete publishItem['validationstatus'];
      delete publishItem['inputstatus'];
      delete publishItem['validationmessage'];
      delete publishItem['inputmessage'];
      return publishItem;
    }));
    return array;
  }


  private async getDomainId(value: string, referencedTableId: string) {
    let code = value;
    if (value.toString().includes('{') && value.toString().includes('}')) {
      code = value.split('{')[0];
    }
    const record = await this.indexeddbService.getDataByKey('Lookup_' + referencedTableId, code.toString());
    return record;
  }

  public clearMessage() {
    setTimeout(() => {
      this.message = '';
      this.cd.detectChanges();
    }, 4000);
  }

  public combineHeaderAndData(header: any[], data: any[]): any[] {
    const combinedData: any[] = [];

    // Iterate through the data array
    for (const row of data) {
      const rowData: any = {};

      // Iterate through the header array and assign values from the corresponding row
      for (let i = 0; i < header[0].length; i++) {
        rowData[header[0][i]] = row[i];
      }

      // Push the row data object to the combinedData array
      combinedData.push(rowData);
    }

    return combinedData;
  }

  public async getAllDomainApi(lookupDataLength: number) {
    this.checkNetwork();
    this.entityId = this.selectedEntity.id;
    await Excel.run(async (context: any) => {
      const worksheets = context.workbook.worksheets;
      worksheets.load("items/name");
      await context.sync();
      const duplicateLookupRemovedHeaders: any = this.appUtilityService.removeDuplicatesBasedOnKey(this.headers, 'referencedTableId')
      for (const item of duplicateLookupRemovedHeaders) {
        if (this.reloadRequired) {
          break;
        }
        if (item.formType === 'LOOKUP') {
          // const existingSheet = worksheets.items.find((sheet: any) => sheet.name === item.selectColumn);
          // if (!existingSheet) {
          this.currentPage = 0;
          if (item?.totalElements < this.totalElementLimit && !this.reloadRequired) {
            await this.createSheetAndAddData(item, worksheets, context);
          }
          // }
        }
      }
      await context.sync();
    });
  }

  public addDisplayNameInFilter(query?: any) {
    const updatedHeaders = this.headers.map((item: any) => {
      const res = item.column.split(",");
      if (res.length > 1) {
        const col = res.find((el: any) => el.endsWith("_code"));
        if (!!col) {
          return { ...item, column: col };
        }
      }
      return { ...item };
    });

    const validationStatus: any = [{
      dataType: "boolean",
      name: "Validation Status",
      formType: "FREE_FORM",
      column: "is_valid",
      options: undefined,
      isFilterable: true,
      referencedTableId: null,
      selectColumn: 'validationstatus',
      systemAttribute: false,
      width: 100,
      autoColumn: true
    }];
    updatedHeaders.push(validationStatus);

    if (!!query && query.rules) {
      query.rules.forEach((el: any) => {
        const item: any = updatedHeaders.find(
          (elm: any) => elm.column === el.field
        );
        if (el?.rules && el?.rules.length > 0) {
          this.addDisplayNameInFilter(el); // Recursively process sub-rules
        }
        if (!!item) {
          el.displayName = item.name;
        }
        if (el.field === "is_valid") {
          el.displayName = "Validation Status";
        }
        return;
      });
    }
    return updatedHeaders;
  }

  public async getLookupData(item: any): Promise<any> {
    let lookupdata: any = [];
    if (item?.referencedTableId) {
      let currentPage = 0;
      let totalPages = 1; // Initialize to 1 to enter the loop

      while (currentPage < totalPages) {
        const pageSize = item.totalElements >= environment.TOTALELEMENTS ? 1 : environment.PAGESIZE;
        const { entityData } = await this.loadLookupData(item?.referencedTableId, currentPage, pageSize);
        lookupdata.push(...entityData.content);
        // if (currentPage === 0 && entityData.totalElements >= this.totalElementLimit) {
        //   this.domainAttributesArray.push({ name: item.selectColumn, elements: entityData.totalElements });
        // }
        currentPage++;
        totalPages = entityData.totalPages;
        // item.totalElements = entityData.totalElements;
        // Check if totalPages exceeds 15,000
        if (entityData.totalElements >= this.totalElementLimit) {
          break;
        }
      }
    }
    return lookupdata;
  }

  public async createSheetAndAddData(item: any, worksheets: any, context: any) {
    await this.tryCatchWrapper(async () => {
      let lookupdata: any = [];
      let dbData: any = [];
      let sheets = worksheets;

      const data = await this.getLookupData(item);
      const totalElements = this.domainAttributesArray.find((el: any) => el.name === item.selectColumn);
      if (!totalElements) {
        if (!!data?.length) {
          lookupdata = data;
          dbData = data;
          dbData = dbData.map((el: any) => ({
            ...el,
            key: el?.code?.toString()
          }));
        }
        let newSheet: any;
        const existingSheet = sheets.items.find((sheet: any) => sheet.name === 'Lookup_' + item?.referencedTableId);
        if (!existingSheet) {
          newSheet = context.workbook.worksheets.add('Lookup_' + item?.referencedTableId);
          newSheet.visibility = 'hidden';
        }
        else {
          newSheet = context.workbook.worksheets.getItem('Lookup_' + item?.referencedTableId);
          let range = newSheet.getUsedRange();
          range.clear();
        }

        await this.tryCatchWrapper(async () => {
          await this.indexeddbService.addStore('Lookup_' + item?.referencedTableId, 'key');
          await this.indexeddbService.addBulkData('Lookup_' + item?.referencedTableId, dbData);
        }, `Error adding bulk data to:', 'Lookup_' + ${item?.referencedTableId}`);

        // Extract the "code" property from each object in the dataArray
        let values = lookupdata?.map((item: any) => {
          const name = !item.name ? '' : item.name;
          const code = !item.code ? '' : item.code;
          const value = !!name && !!code ? `${code}{${name}}` : !name && code ? `${code}` : name;
          return [value, item.id]
        });
        const lookupRange = newSheet.getRange(`A1:B${values.length}`);
        lookupRange.values = values;

      }
    }, 'createDataSheet');
  }

  public loadLookupData(id: number, pageNumber: any, pageSize: number): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.entityService.loadLookupData(id, pageNumber, pageSize, [], [{ direction: "ASC", property: "code" }]).pipe(
        takeUntil(this.subscription$)
      ).subscribe(
        (entityData: any) => {
          resolve({
            entityData,
          });
        },
        (err: any) => {
          if (err.status === 401) {
            localStorage.removeItem('token');
            this.route.navigate(['/landing']);
          }
          this.loading = false;
          this.overLay = false
          rejects({
            entityData: [],
          });
        }
      );
    });
  }
  public async getAllTableBySheet() {
    await Excel.run(async (context: any) => {
      const tables = context.workbook.tables;
      tables.load('items/name');
      await context.sync();
      if (tables.items.length > 0) {
        this.addedSheetsNames = tables.items.map((elm: any) => elm.name);
      }
    });
  }


  // Handle this function for clear the cells
  public async clearExcelCells(removeSheet: boolean, removeData: boolean, entity: any) {
    this.readColumns = [];
    this.autoColumns = [];
    try {
      await Excel.run(async (context: any) => {
        // let sheet = context.workbook.worksheets.getActiveWorksheet();
        let sheets = context.workbook.worksheets;
        sheets.load("items/name");
        // sheet.protection.unprotect("SIM8Eight#");
        await context.sync();
        const sheetName = this.handleSheetName(entity?.displayName);

        // Handle sheet removal and data removal
        if (removeSheet || removeData) {
          const existingSheetIndex = sheets.items.findIndex((sheet: any) => sheet.name === sheetName);
          let newSheet;

          if (existingSheetIndex !== -1) {
            const existingSheet = sheets.items[existingSheetIndex];
            await this.renameSheet(existingSheet, "Deleted");

            newSheet = sheets.add(sheetName);
            newSheet.activate();

            // await context.sync();
            existingSheet.delete();
          } else {
            newSheet = sheets.add(sheetName);
            newSheet.activate();
          }

          newSheet.name = sheetName;
          if (removeSheet) {
            await this.deleteOtherSheets(sheets, sheetName, context);
          }
        }
        await context.sync();
      });
    } catch (error: any) {
      console.error("Error:", error.message);
      if (error instanceof OfficeExtension.Error) {
        console.error("Debug info:", JSON.stringify(error.debugInfo));
      }
    }
  }

  public async renameSheet(sheet: any, newName: string) {
    sheet.name = newName;
    await sheet.context.sync();
  }

  public async deleteOtherSheets(sheets: any, excludeSheetName: string, context: any) {
    for (const item of sheets.items) {
      if (['Sheet1'].includes(item.name)) {
        item.delete();
      }
      // if (item.name !== excludeSheetName) {
      //   item.delete();
      // }
    }
    // await context.sync();
  }

  public handleSheetName(name: string) {
    let sheetName: any = name.trim();
    if (sheetName.length > 31) {
      sheetName = sheetName.substring(0, 31);
    }
    return sheetName;
  }

  public async convertSheetToTable(sheet: any, context: any, usedRange: any) {
    this.contentName = 'Updating Sheet...'
    const startRow = 0;
    const startColumn = 0;
    const rowCount = usedRange.rowCount;
    const columnCounts = usedRange.columnCount;

    // Get the range of the data to be converted to a table
    const dataRange = sheet.getRangeByIndexes(startRow, startColumn, rowCount, columnCounts);

    // Convert the range to a table
    const table = sheet.tables.add(dataRange, true /* hasHeaders */);
    this.tableName = `Entity_${this.selectedEntity?.id}`;
    table.name = this.tableName;
    this.onChangedEventHandler = table.onChanged.add(this.excelChangeEvent.bind(this));
    const columnCRange = sheet.getRange('C:C').getUsedRange();
    columnCRange.format.load("wrapText");
    // Enable text wrapping for column C
    columnCRange.format.wrapText = false;
    await context.sync();
    // if all the record are got delete
    if (this.previousTableDatas?.length === 1 &&
      Object.values(this.previousTableDatas[0]).every(value => value?.toString()?.trim() === "")) {
      await this.manageRowIdentifierRow(sheet);
      await context.sync();
    }
    this.contentName = 'Updating Sheet Completed...'
    if (!this.isCodeNull) {
      this.message = {
        message: 'Rules Validated Successfully.',
        type: 'success'

      }
    }
    this.unsubscribeNetwork();
    this.reloadRequired = false;
    if (!!this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.loading = false;
    this.disablePublishButton = false;
    this.overLay = false
    this.indexeddbService.closeDb();
    if (!!this.currentDialog) {
      this.currentDialog.close()
    }
    if (!!this.intervalId) {
      clearInterval(this.intervalId);
    }
    // sheet.getUsedRange().format.autofitColumns();
    // sheet.getUsedRange().format.autofitRows();
    // clearInterval(this.intervalId);
    this.cd.detectChanges();
    // this.clearMessage();
  }
  checkSheetPermission() {
    Office.context.ui.displayDialogAsync(
      `${environment.URL}/#/missing/load`, { height: 30, width: 30 },
      (asyncResult: any) => {
        const dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
          dialog.close();
          setTimeout(() => {
            this.openDialogBox(this.entity);
          }, 100);
          // this.reloadData();
        })
      }
    );
  }

  public handleMismatchVersionDialog(message: any) {
    this.loading = false;
    this.cd.detectChanges();
    Office.context.ui.displayDialogAsync(
      `${environment.URL}/#/confirm`,
      { height: 30, width: 30 },
      (item) => {
        const dialog = item.value;
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          async (arg: any) => {
            if (arg?.message === 'send') {
              setTimeout(() => {
                const data = { message: message, ok: 'Ok' }
                dialog.messageChild(JSON.stringify(data));
              }, 800);
            }
            if (arg?.message == 'continue') {
              dialog.close();
            }
          });
      });
  }

  public async publishRecords() {
    this.contentName = 'Loading Batches...'
    await Excel.run(async (context: any) => {
      // if (this.onChangedEventHandler) {
      //   await this.removeEventHandler();
      // }
      changeDetectionEventInProgress=true;
      const sheet = context.workbook.worksheets.getActiveWorksheet();

      //Add a sheet to show while publish is in progress
      this.processSheet = context.workbook.worksheets.add('Processing');
      // Add text "publishing in progress" in cell A1
      const processRange = this.processSheet.getRange("A1");
      processRange.values = [["Publishing in progress. Please wait..."]];
      // Protect the sheet
      this.processSheet.protection.protect();

      sheet.visibility = 'hidden';
      const range = sheet.getUsedRange();
      range.load(["rowCount", "columnCount"]);
      this.publishContext = context;
      this.publishSheet = sheet;
      this.processSheet.activate();
      await context.sync();
      this.rowCount = range.rowCount;
      this.columnCount = range.columnCount;
      const publishDataValues = await this.loadBatch(sheet, context);
      if (publishDataValues.length > 0) {
        this.overLay = true;
        this.loading = true;
        this.networkSubscription = this.networkService.isOnline.subscribe(async isOnline => {
          if (!isOnline) {
            this.currentDialog.close();
            this.publishSheet.visibility = 'visible';
            this.publishSheet.activate();
            this.processSheet.delete();
            await this.publishContext.sync();
            this.reloadRequired = true;
            setTimeout(() => {
              this.publishWorker.terminate();
              this.handleOfflineDialog();
            }, 500);
          }
        })
        this.publishWorker.postMessage
          (
            {
              rows: publishDataValues,
              batchSize: environment.PUBLISHRECORD,
              version: environment.VERSION,
              headers: this.headers,
              totalElementLimit: this.totalElementLimit,
              api_url: environment.API_DATA_URL,
              token: localStorage.getItem('token'),
              entityId: this.entityId,
              readColumns: this.readColumns.length === this.currentSheetHeaders.length,
            }
          );
      }
    })
  }

  private async loadBatch(sheet: any, context: any) {
    let batchData = [];
    const batchSize = environment.PUBLISHRECORD;
    const totalBatch = Math.ceil(this.rowCount / environment.PUBLISHRECORD);
    const totalRows = this.rowCount;
    for (let i = 0; i < totalBatch; i++) {
      const startIndex = i * batchSize;
      const endIndex = Math.min(startIndex + batchSize, totalRows);
      const batch = sheet.getRangeByIndexes(startIndex, 0, endIndex - startIndex, this.columnCount);
      batch.load("values");
      await context.sync();
      batchData.push(...batch.values);
    }
    return batchData;
  }

  private async publishWorkerMessage(event: MessageEvent) {
    const result = event.data
    this.contentName = `Publishing changes batch ${result.count} of ${result.totalBatchPublish}`;
    if (result.action === "published") {
      await this.updatePublishedResponseMessage(this.publishSheet, result.data, this.publishContext);
    }
    if (result.action === "deleted") {
      await this.updateDeletedResponseMessage(this.publishSheet, result, this.publishContext);
    }
    if (result.action == "ApiError") {
      await this.updatePublishedApiError(this.publishSheet, result.data, this.publishContext);
    }
    if (result.action == "unauthorized") {
      this.currentDialog.close();
      localStorage.removeItem('token');
      this.route.navigate(['/landing']);
    }
    if (result.action === "invalid-verison") {
      this.currentDialog.close();
      setTimeout(() => {
        this.handleMismatchVersionDialog(result?.message)
      }, 500);
    }
    if (result.count == result.totalBatchPublish) {
      setTimeout(async () => {
        const entity: any = this.entityList.find((el: any) => el.id === this.entityId);
        this.publishSheet.visibility = 'visible';
        this.publishSheet.activate();
        this.processSheet.delete();
        if (result.action !== "ApiError") {
          this.addPublishStatus(this.publishContext, true);
        }
        // const table = this.publishContext.workbook.tables.getItem(this.tableName);
        // this.onChangedEventHandler = table.onChanged.add(this.excelChangeEvent.bind(this));
        if (result.erroBatch.length > 0) {
          await this.updateErrorRecords(result.erroBatch, this.publishContext);
        }
        this.setAllColors(this.publishSheet, this.publishContext);
        await this.publishContext.sync();
        this.message = {
          message: `Record published successfully in "${entity?.displayName}"`,
          type: 'success'
        }
        this.loading = false;
        this.overLay = false;
        hasChangedRow = false;
        this.indexeddbService.closeDb();
        if (!!this.currentDialog) {
          this.currentDialog.close()
        }
        if (!!this.intervalId) {
          clearInterval(this.intervalId)
        }
        this.contentName = '';
        this.cd.detectChanges();
        this.clearMessage();
        changeDetectionEventInProgress=false;
      }, 100);
    }
  }

  public async updatePublishedResponseMessage(sheet: any, records: any, context: any) {
    records.forEach(async (record: any) => {
      const rowNumber = record[record.length - 1];
      const range = sheet.getRangeByIndexes(rowNumber - 1, 0, 1, this?.headers?.length);
      range.values = [record];
      range.format.fill.clear();
      if (record[3] !== "Error" && record[1] !== "Validation Failed") {
        const range = sheet.getRange(`B${rowNumber}:E${rowNumber}`);
        range.format.font.color = "#000000";
      }
      else if (record[3] === "Error") {
        range.format.fill.color = "#ffcecb";
        const rowRange = sheet.getRange(`D${rowNumber}:D${rowNumber}`);
        rowRange.format.font.color = "#c5261f";
      }
      else {
        const rowRange = sheet.getRange(`B${rowNumber}:C${rowNumber}`);
        rowRange.format.font.color = "#c5261f";
      }
    });
    await context.sync();
  }

  public async updatePublishedApiError(sheet: any, record: any, context: any) {
    record.forEach((record: any) => {
      const rowNumber = record.row;
      const range = sheet.getRange(`A${rowNumber}:E${rowNumber}`);
      range.values = [record.value];
      const clearRange = sheet.getRange(`F${rowNumber}:F${rowNumber}`);
      clearRange.clear();
      if (record.value[3] !== "Error" && record.value[1] !== "Validation Failed") {
        range.format.font.color = "#000000";
      }
      else if (record.value[3] === "Error") {
        const fullRow = sheet.getRangeByIndexes(rowNumber - 1, 6, 1, this?.headers?.length - 9);
        fullRow.format.fill.color = "#ffcecb";
        const rowRange = sheet.getRange(`D${rowNumber}:D${rowNumber}`);
        rowRange.format.font.color = "#c5261f";
      }
      else {
        const rowRange = sheet.getRange(`B${rowNumber}:C${rowNumber}`);
        rowRange.format.font.color = "#c5261f";
      }
    });
    await context.sync();
  }

  public async updateDeletedResponseMessage(sheet: any, result: any, context: any) {
    result?.data.forEach((record: any) => {
      const rowNumber = record.row;
      const range = sheet.getRange(`A${rowNumber}:E${rowNumber}`);
      range.values = [record.value];
      const clearRange = sheet.getRange(`F${rowNumber}:F${rowNumber}`);
      clearRange.clear();
      if (record.value[3] !== "Error" && record.value[1] !== "Validation Failed") {
        range.format.font.color = "#000000";
      }
      else if (record.value[3] === "Error") {
        const fullRow = sheet.getRangeByIndexes(rowNumber - 1, 6, 1, this?.headers?.length - 9);
        fullRow.format.fill.color = "#ffcecb";
      }
    });

    if (result?.deletedRows?.length > 0) {
      // Array of row numbers to delete
      let rowsToDelete = result?.deletedRows; // Specify the rows you want to delete
      rowsToDelete.sort((a: any, b: any) => b - a);
      for (let rowNum of rowsToDelete) {
        let range = sheet.getRange(`A${rowNum}:XFD${rowNum}`);
        range.delete(Excel.DeleteShiftDirection.up);
      }
    }
    this.totalSelectedRowForDeletion = 0;
    await context.sync();
  }
  public async updateErrorRecords(records: any, context: any) {
    try {
      records?.forEach((item: any) => {
        const rowNumber = item[item.length - 1];
        const errorRange = this.publishSheet.getRangeByIndexes(rowNumber - 1, 6, 1, this?.headers?.length - 9);
        errorRange.format.fill.color = "#ecc0be";
        const range= this.publishSheet.getRange(`D${rowNumber}:D${rowNumber}`)
        range.format.font.color='#c5261f';
      })
      await context.sync();
    }
    catch (error) {
      console.log(error)
    }
  }
  checkNetwork() {
    this.entityId = this.selectedEntity.id;
    this.networkSubscription = this.networkService.isOnline.subscribe(isOnline => {
      if (!isOnline) {
        this.reloadRequired = true;
        this.subscription$.next();
        if (!!this.currentDialog) {
          this.currentDialog.close();
        }
        setTimeout(() => {
          this.handleOfflineDialog();
        }, 500);
      }
    })
  }
  public async validateOrPublishRecords(type?: any): Promise<any> {
    await this.indexeddbService.ensureDbOpen();
    this.checkNetwork();
    this.disablePublishButton = true;
    if (type == 'validate' && this.roleChanged && !this.sheetLoaded[this.entity?.displayName.trim().replace(/\s+/g, "")] && this.existingSheets.includes(this.entity.displayName)) {
      this.checkSheetPermission()
    }
    else {
      this.updatedData = null;
      this.isCodeNull = false;
      this.loading = true;
      this.overLay = true;
      this.contentName = 'Loading Batches...'
      await Excel.run(async (context: any) => {
        return new Promise<void>(async (resolve, reject) => {
          try {
            const sheet = context.workbook.worksheets.getActiveWorksheet();
            if (this.onChangedEventHandler) {
              await this.removeEventHandler();
            }

            const preTable = context.workbook.tables.getItem(this.tableName);

            // Convert the table to a range
            var range = preTable.convertToRange();
            this.headers = [];
            const settings = context.workbook.settings;

            // let table = sheet.tables.getItem(this.tableName);
            const entityMetaData = settings.getItem(this.tableName);
            entityMetaData.load("value");
            // const headerRowRange = table.getHeaderRowRange();
            // headerRowRange.load("values");

            // Load the rowCount and columnCount properties of the table
            const usedRange = sheet.getUsedRange();
            usedRange.load('rowCount, columnCount');

            // const comments = sheet.comments;
            const comments = null
            // Loop through all comments to find if there's one for the specific cell
            // comments.load("items");

            await context.sync();

            const data = JSON.parse(entityMetaData.value);
            this.entityId = data?.entity_id;
            this.domainAttributesArray = data?.attributeNamesToReplaceCodeWithId;
            this.headers = data?.tableHeaders;

            const rowCount = usedRange.rowCount;
            const columnCount = usedRange.columnCount;
            const pageSize = type === 'publish' ? environment.PUBLISHRECORD : 1000; // Number of rows to load at a time
            let startRow = 0;
            let totalBatchPublish = Math.ceil(rowCount / pageSize);
            let index = 1;
            let currentDataLength = 0;
            let record: any = null;

            const currentRange = sheet.getRangeByIndexes(startRow + 1, 0, rowCount, columnCount); // +1 to skip header
            currentRange.load("values");
            await context.sync();
            while (startRow < rowCount) {
              if (this.reloadRequired == true) {
                break;
              }
              const values = [...currentRange.values].splice(startRow, pageSize);
              if (values.length > 0) {
                currentDataLength += values.length;
                const result = await this.formatEditData(values);
                const items = result.editRecords;
                const deleteRecord = result.deletedRecords;
                if (items.length > 0 || deleteRecord.length > 0) {
                  if (type === 'publish') {
                    this.contentName = `Publishing changes batch ${index} of ${totalBatchPublish}...`;
                    record = await this.updateEntityRecords(items, deleteRecord, sheet, context, startRow);
                    if (!!record) {
                      await this.updateCellWithStatus(sheet, record, context, 'changed');
                    }
                  }
                  else {
                    this.contentName = `Validating changes batch ${index} of ${totalBatchPublish}...`;
                    const { entityData } = await this.getAllValidationRules(items, index);
                    record = entityData;
                    if (!!record) {
                      await this.updateCellWithStatus(sheet, record, context);
                    }
                  }
                }
                startRow += pageSize;
                index++;
              }
              if (startRow >= rowCount && !this.reloadRequired) {
                // if (!record) {
                this.loading = false;
                this.overLay = false
                // }
                // else {
                await this.convertSheetToTable(sheet, context, usedRange);
                // }
                break;
              }
            }
            resolve();
            return this.editRecords;
          }
          catch (error) {
            reject(error);
            if (!!this.currentDialog) {
              this.currentDialog.close()
            }
            clearInterval(this.intervalId);
            this.disablePublishButton = false;
            this.cd.detectChanges();
          }
        });
      }).catch((error: any) => {
        console.error(error, 'error');
      });
    }
  }

  public updateEntityInBluk(data: any, deletedIds: any, sheet: any): Promise<any> {
    return new Promise((resolve, rejects) => {
      combineLatest([
        this.entityService.updateEntityDataINBulk(this.entityId, data).pipe(
          takeUntil(this.subscription$)
        ),
        // deletedIds.length > 0 ? this.entityService.deleteEntityDataINBulk(this.entityId, deletedIds).pipe(
        //   takeUntil(this.subscription$)
        // ) : ''
      ]).subscribe(
        ([entityData]) => {
          resolve({
            entityData
          });
        },
        (err: any) => {
          if (err.status === 401) {
            localStorage.removeItem('token');
            this.route.navigate(['/landing']);
          }
          if (err.status === 400) {
            console.log(err, ' input')
            if (err?.error?.message) {
              const errors = JSON.parse(err?.error?.message) || [];
              if (errors) {
                this.errorMessageHandler(errors, sheet);
              }
            }
          }
          // this.loading = false;
          // this.overLay = false
          resolve({
            entityData: null
          });
        }
      );
    });
  }

  public async errorMessageHandler(items: any, sheet: any) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const cellAddress = `D${item.__ROW_IDENTIFIER}`;
      let inputMessageField = sheet.getRange(`E${item.__ROW_IDENTIFIER}`);
      let inputValue = sheet.getRange(cellAddress);
      const inputStatus = 'Error';
      inputValue.values = [[inputStatus]];
      const errorRange = sheet.getRange("A" + item.__ROW_IDENTIFIER + ":XFD" + item.__ROW_IDENTIFIER);
      errorRange.getUsedRange().format.fill.color = "#ecc0be";
      // find the row with rowidentifier and then reasign the values
      inputMessageField.values = [[item.message]];
    }
  }

  public async updateEntityRecords(record: any, deleteRecord: any, sheet: any, context: any, startRow: any): Promise<any> {
    if (this.isCodeNull) {
      this.currentDialog.close();
      setTimeout(() => {
        Office.context.ui.displayDialogAsync(
          `${environment.URL}/#/missing/cell`, { height: 25, width: 20 },
          (asyncResult: any) => {
            const dialog = asyncResult.value;
            dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
              dialog.close();
              this.overLay = false
            })
          }
        );
      }, 500);
      return false;
    }

    if (record.length > 0 && !this.isCodeNull) {
      this.editRecords = await this.updateDomainAttributeFields(record);
      const attributeNames = this.headers
        .filter((elm: any) => elm.totalElements > this.totalElementLimit)
        .map((elm: any) => elm.selectColumn);

      let data = this.editRecords;
      data = attributeNames.length > 0 ? { data, attributeNamesToReplaceCodeWithId: attributeNames } : { data };
      const { entityData } = await this.updateEntityInBluk(data, deleteRecord, sheet);
      return entityData;
    }
  }

  public async getAllValidationRules(record: any, vIndex: any): Promise<any> {
    const updatedRecords = await this.updateDomainAttributeFields(record)
    return new Promise((resolve, rejects) => {
      this.entityService.getValidationRules(this.entityId, updatedRecords, vIndex).subscribe(
        (entityData: any) => {
          resolve({
            entityData,
          });
        },
        (err: any) => {
          if (err.status === 401) {
            localStorage.removeItem('token');
            this.route.navigate(['/landing']);
          }
          if (err?.error?.errorCode === "VEX-400") {
            !!this.currentDialog ? this.currentDialog.close() : '';
            setTimeout(() => {
              this.handleMismatchVersionDialog(err?.error?.message);
            }, 500);
          }
          resolve({
            entityData: [],
          });
        }
      );
    });
  }

  public async formatEditData(data: any): Promise<any> {
    const lowercasedHeaderData = [this.headers.map((el: any) => { return el.selectColumn })];
    const editedRowsData = data.filter((el: any) => ['Deleted', 'Changed', 'New Row'].includes(el[3]));
    let finalData = this.combineHeaderAndData(lowercasedHeaderData, editedRowsData);
    finalData = finalData?.map((el: any) => ({
      ...el,
      id: !['', null, undefined].includes(el?.id) ? el.id : 0,
      comment: null
    }));
    this.editRecords = finalData;
    this.editRecords = this.editRecords.map((item: any) => {
      const newItem = { ...item };
      for (const [key, value] of Object.entries(newItem)) {
        const item = this.headers.find((elm: any) => elm.selectColumn === key);
        if (['#NAME?', '#N/A'].includes(newItem[key]) || (!!item && item.selectColumn !== 'id') && (item?.autoGenerated && !value)) {
          newItem[key] = null;
        }
        if (item?.selectColumn == "code" && !item?.autoGenerated && [null, "", undefined].includes(newItem[key])) {
          this.isCodeNull = true
        }

      }
      return newItem;
    });
    // filter only deleted records
    this.deletedRecords = this.editRecords.reduce((acc: any[], el: any) => {
      if (el.inputstatus === 'Deleted') {
        acc.push(el.id);
      }
      return acc;
    }, []);
    // filter only edit records
    this.editRecords = this.editRecords.filter((el: any) => ['Changed', 'New Row'].includes(el.inputstatus));
    const result = { editRecords: this.editRecords, deletedRecords: this.deletedRecords }
    return result;
  }

  public async deleteRows(range: any) {
    if (!!this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (range?.length > 0) {
      this.totalSelectedRowForDeletion = range.length;
    }
    Office.context.ui.displayDialogAsync(
      `${environment.URL}/#/confirm`,
      { height: 30, width: 40 },
      (item) => {
        const dialog = item.value;
        this.currentDialog = dialog;
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          async (arg: any) => {
            if (arg?.message === 'send') {
              setTimeout(() => {
                const data = { message: `Selected ${this.totalSelectedRowForDeletion} rows will be deleted on clicking the publish button. Do you want to continue?`, yes: 'Yes', no: 'No' }
                dialog.messageChild(JSON.stringify(data));
              }, 800);
            }
            // dialog.close();
            if (arg?.message === 'continue') {
              dialog.close();
              if (range.length > 0) {
                await Excel.run(async (context: any) => {
                  try {
                    range.forEach(async (item: any) => {
                      const cellAddress = `D${item}:D${item}`;
                      const sheet = context.workbook.worksheets.getActiveWorksheet();
                      var inputFieldRange = sheet.getRange(cellAddress);
                      inputFieldRange.values = [["Deleted"]];
                      const row = sheet.getRange(`${item}:${item}`);
                      row.getUsedRange().format.fill.color = "#ffcecb";
                      await context.sync();
                      this.deletionRowRange = null;
                      hasChangedRow = true;
                    })
                    if (this.disablePublishButton == true) {
                      this.addPublishStatus(context, false);
                    }
                  }
                  catch (err) {
                    console.log(err)
                  }
                })
              }
            }
            if (arg?.message === 'close') {
              dialog.close();
            }
          });
      });
  }

  public HandleSearchModel(text: any) {
    this.filteredModelList = this.modelList.filter((item: any) => {
      return item.displayName
        .trim()
        .toLowerCase()
        .includes(text.trim().toLowerCase());
    });
  }

  // Handle this function for adding the details in office settings.
  public async addEntityMetaData(filter: any, entity: any, currentSheetPermission: any) {
    await Excel.run(async (context: any) => {
      const settings = context.workbook.settings;
      // const range = settings.getItemOrNullObject(`Entity_${this.selectedEntity.id}`);
      const sheetName = this.handleSheetName(entity?.displayName);
      const jsonObject = {
        sheetName: sheetName,
        filter: filter,
        entity_id: entity?.id,
        tableHeaders: this.headers,
        query: this.query,
        selectedHeaders: this.selectedHeaders,
        attributeNamesToReplaceCodeWithId: this.domainAttributesArray,
        visibleHeaders: this.visibleHeaders,
        readColumns: this.readColumns,
        autoColumns: this.autoColumns,
        currentSheetPermission: currentSheetPermission
      };
      settings.add(`Entity_${this.selectedEntity?.id}`, JSON.stringify(jsonObject));
      await context.sync();
    }).catch((error: any) => {
      console.error(error);
    });
  }

  public flattenObject(obj: any) {
    let flattened: any = {};
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        flattened[key] = JSON.stringify(obj[key]); // Convert array to JSON string
      } else {
        flattened[key] = obj[key];
      }
    }
    return flattened;
  }

  public restoreObject(flattened: any) {
    const restored: any = {};
    for (const key in flattened) {
      if (flattened.hasOwnProperty(key)) {
        try {
          restored[key] = JSON.parse(flattened[key]); // Convert JSON string back to array/object
        } catch (e) {
          restored[key] = flattened[key]; // If not a JSON string, use the original value
        }
      }
    }
    return restored;
  }

  public async currentSheetMetaData(entity: any) {
    await Excel.run(async (context: any) => {
      this.headers = [];
      // Get the worksheet
      const settings = context.workbook.settings;

      // Sync the context to execute the queued commands
      // await context.sync();
      if (!!entity?.id) {
        const range = settings.getItemOrNullObject(`Entity_${entity.id}`);
        range.load("value");
        await context.sync();
        if (!range.isNullObject) {
          this.entityMetaData = JSON.parse(range.value);
          if (!!this.entityMetaData) {
            this.entityId = this.entityMetaData?.entity_id || null;
            this.headers = this.entityMetaData?.tableHeaders;
            this.query = this.entityMetaData?.query;
            this.previousQuery = this.entityMetaData?.query;
            this.filter = this.entityMetaData?.filter;
            this.domainAttributesArray = this.entityMetaData?.attributeNamesToReplaceCodeWithId;
            this.selectedHeaders = this.entityMetaData?.selectedHeaders;
            this.selectedEntity = this.entityList.find((el: any) => el.id === this.entityId);
            this.showFilter = true;
            this.visibleHeaders = this.entityMetaData?.visibleHeaders
            this.cd.detectChanges();
          }
        }
        else {
          this.filter = []
          this.selectedHeaders = []
          this.query = null;
          this.previousQuery = null;
          this.isUpdate = false;
        }
      }
    });
  }

  public async getCurrentTableName() {
    await Excel.run(async (context: any) => {
      var sheet = context.workbook.worksheets.getActiveWorksheet();
      // Load the collection of tables in the active worksheet
      const tables = sheet.tables;
      tables.load("items/name");

      // Sync the context to execute the queued commands
      await context.sync();

      if (tables?.items?.length > 0) {
        let tableNames = tables.items[0].name;
        this.tableName = tableNames;
        return tableNames;
      }
      return true;
    });
  }

  ngOnDestroy(): void {
    this.subscription$.next();
    clearInterval(this.intervalId);
    this.unsubscribeNetwork();
    window.location.reload();
  }
  private unsubscribeNetwork() {
    if (this.networkSubscription) {
      this.networkSubscription.unsubscribe();
      this.networkSubscription = null;
    }
  }

  public async excelChangeEvent(event: any) {
    if (event.changeType == "RowInserted" && changeDetectionEventInProgress) {
      this.insertedRow.push(event);
    }
    if (!changeDetectionEventInProgress && (event.details?.valueAfter == null || event.details.valueAfter !== 'Deleted')) {
      changeDetectionEventInProgress = true;
      const cells = event.address.split(',');
      const changedCellAddress = event.address;
      const rowSequential = areRowsSequential(cells);
      let cellRanges: any;
      const fullRange = convertCellsToRange(event.address, rowSequential);
      if (fullRange?.length > 1) {
        cellRanges = fullRange
      }
      else {
        cellRanges = event.address.split(":");
      }
      await this.indexeddbService.ensureDbOpen();
      let firstLetter: any;
      let secondLetter: any;
      let rangeArray: any = [];
      let changedIndex: number;

      let changeRange: any;
      let changedColumnIndices: any = []

      if (cellRanges.length === 2 && rowSequential) {
        firstLetter = cellRanges[0].match(/([A-Z]+)(\d+)/);
        secondLetter = cellRanges[1].match(/([A-Z]+)(\d+)/);
        rangeArray = generateRange(+firstLetter[2], +secondLetter[2]);
        changedIndex = secondLetter[1] ?
          this.columnIndexFromLetter(secondLetter[1]) : this.columnIndexFromLetter(firstLetter[1]);
        changeRange = `F${firstLetter[2]}:F${secondLetter[2]}`;
        changedColumnIndices = this.appUtilityService.indicesFromLetterToLetter(firstLetter[1], secondLetter[1]);
      }
      else {
        changedIndex = this.findLargestColumnIndex(event.address);
        if (rowSequential) {
          changeRange = `F${cellRanges[0].replace(/\D/g, '')}`;
          changedColumnIndices = [changedIndex - 1];
        }
      }
      var match = changedCellAddress.match(/([A-Z]+)(\d+)/);
      var columnLetter = match[1];
      var rowNumber = parseInt(match[2]);
      await Excel.run({ delayForCellEdit: true }, async (context: any) => {
        try {
          if (!["D", "B", "C", "E", "A", "F"].includes(columnLetter) && rangeArray.length > this.environment?.VALIDATIONPOPUPLENGTH) {
            this.loading = true;
            this.overLay = true;
            this.contentName = 'Data validation is in progress.';
            this.cd.detectChanges();

            // Handle this when any change in excel then loader showing
            Office.context.ui.displayDialogAsync(
              `${environment.URL}/#/loader`, { height: 50, width: 60 },
              (asyncResult: any) => {
                const dialog = asyncResult.value;
                this.currentDialog = dialog;
                dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (arg: any) => {
                  if (arg?.message === 'start') {
                    setTimeout(() => {
                      dialog.messageChild(JSON.stringify(this.contentName));
                    }, 800);
                  }
                })
              }
            );
          }
          let entityMetaData: any;
          let failedValidationRows: any;
          context.runtime.enableEvents = false;
          // getting all meta data
          const settings = context.workbook.settings;
          await this.getCurrentTableName();
          if (!!this.tableName) {
            entityMetaData = settings.getItem(this.tableName);
            failedValidationRows = settings.getItemOrNullObject(`${this.tableName}_error_status`);
            failedValidationRows.load("value");
            entityMetaData.load("value");
          }
          var sheet = context.workbook.worksheets.getActiveWorksheet();
          let inputValue = sheet.getRange(`D${rowNumber}`);
          let inputMessgeValue = sheet.getRange(`E${rowNumber}`);
          let currentLookupData: any;

          const usedRange = sheet.getUsedRange();
          usedRange.load('rowCount, columnCount');


          const changedRange = sheet.getRange(changeRange);
          range = changedRange;
          changedRange.load("values");

          await context.sync();

          if (event?.changeType === 'RangeEdited' && !["D", "B", "C", "E", "A", "F"].includes(columnLetter) && changedIndex < this.currentSheetHeaders.length && rowSequential) {
            this.addToChangeColumn(changedRange.values, changedColumnIndices, context, range);
          }
          const data = JSON.parse(entityMetaData?.value);
          failedValidationRows = !failedValidationRows._isNull ? JSON.parse(failedValidationRows?.value) : null;
          const currentSheetPermission = data?.currentSheetPermission || [];
          const readPermissionData = currentSheetPermission?.findIndex((el: any) => ['READ', 'DENY'].includes(el));
          this.currentSheetHeaders = data?.tableHeaders || this.currentSheetHeaders;
          const headerItem = this.currentSheetHeaders[changedIndex - 1];
          if (!!headerItem?.referencedTableId && headerItem?.totalElements < this.totalElementLimit) {
            currentLookupData = await this.indexeddbService.getAllData('Lookup_' + headerItem.referencedTableId);
          }

          let rowCount = usedRange.rowCount;
          let inputStatus: any;
          let idColumnVal: any;
          if (!!rowCount) {
            const rowRange = sheet.getRangeByIndexes(rowNumber - 1, 0, 1, usedRange.columnCount);
            rowRange.load('values');
            await context.sync();
            inputStatus = rowRange.values[0][3];
            idColumnVal = rowRange.values[0][0];
          }

          if (!!event?.details && event?.details?.valueAfter === event?.details?.valueBefore && inputStatus === 'Unchanged') {
            const currentRange = sheet.getRange(event.address);
            currentRange.format.fill.clear();
          }

          // Handle this condition when row is not sequential
          if (event?.changeType === 'RangeEdited' && cellRanges.length >= 2 && !rowSequential && changedIndex <= this.currentSheetHeaders.length) {
            for (let i = 0; i < cellRanges.length; i++) {
              const address = cellRanges[i];
              const rowNumber: any = parseRowNumbers(address);
              const cellRange = generateRange(+rowNumber[0], +rowNumber[1]);
              cellRange.forEach(async (row: any, index: number) => {
                const permission = !!currentSheetPermission && currentSheetPermission.length > 0 ? currentSheetPermission[row - 2] : null;
                if ((!!permission && permission !== 'UPDATE') || ["D", "B", "C", "E", "A", "F"].includes(columnLetter) || changedIndex == this.currentSheetHeaders.length) {
                  this.addColumnErrorValidations(sheet, address, 'permission', row, settings, failedValidationRows, columnLetter, headerItem?.name, false);
                }
                else {
                  const inputField = sheet.getRange(`D${row}`);
                  const value = inputStatus === 'Deleted' ? [['Deleted']] : inputStatus !== '' ? [['Changed']] : [['New Row']];
                  inputField.values = value;
                  const changeColumnColor = sheet.getRange(address);
                  changeColumnColor.format.fill.color = "#ffd3a9";
                }
              });
              const sortedRowNumbers = rowNumber.sort((a: number, b: number) => a - b);
              const changedRange = sheet.getRange(`F${sortedRowNumbers[0]}:F${sortedRowNumbers[1]}`);
              changedRange.load("values");
              await context.sync();
              const changedColumnLeters = this.appUtilityService.extractColumnLetters(address)
              changedColumnIndices = this.appUtilityService.indicesFromLetterToLetter(changedColumnLeters.firstColumn, changedColumnLeters.secondColumn);
              this.addToChangeColumn(changedRange.values, changedColumnIndices, context, changedRange);
            }
            if (this.disablePublishButton == true) {
              this.addPublishStatus(context, false);
            }
            await context.sync();
          }

          if (["CellInserted", "RowInserted"].includes(event.changeType) && !event.details) {
            var range = sheet.getRange(event.address);
            let inputMessages: any = [];
            let settingsData: any;
            let status: any;
            let newCurrentLookupData: any;
            let validationMessage: any;
            let inputStatusArray: any = [];
            const cellAddress = `D${firstLetter[2]}:D${secondLetter[2]}`;
            const inputMessageField = sheet.getRange(`E${firstLetter[2]}:E${secondLetter[2]}`);
            const inputFieldRange = sheet.getRange(cellAddress);
            inputMessageField.load('values');
            inputFieldRange.load('values');
            range.format.fill.color = "#ffd3a9";
            range.load('values');
            await context.sync();

            if (range?.values?.length > 0) {
              for (let rowIndex = 0; rowIndex < range?.values?.length; rowIndex++) {
                const item = range?.values[rowIndex];
                // Handle this with looping and checking column values
                for (let columnIndex = 0; columnIndex < item?.length; columnIndex++) {
                  const value = item[columnIndex];
                  const lastColumn = item[item.length - 1];
                  // If value is not blank
                  if (!["", null, undefined].includes(value) && value !== lastColumn) {
                    const headerValue = this.headers[columnIndex];
                    // If column is domain based
                    if (!!headerValue?.referencedTableId && headerValue?.totalElements < this.totalElementLimit) {
                      newCurrentLookupData = await this.indexeddbService.getAllData('Lookup_' + headerValue.referencedTableId);
                    }
                    const valueMatch = await this.matchColumnValuesWithValidation(headerValue, value, newCurrentLookupData);
                    // When value is not matched
                    if (!valueMatch || headerValue?.autoGenerated || headerValue?.permission !== 'UPDATE') {
                      const columnLetter = ExcelHelper.getColumnLetter(columnIndex);
                      const address = `${columnLetter}${rangeArray[rowIndex]}`;
                      const message = (headerValue?.autoGenerated || headerValue?.permission !== 'UPDATE') ? 'permission' : 'validation';
                      let result: any = this.addColumnErrorValidations(sheet, address, message, rangeArray[rowIndex], settings, failedValidationRows, columnLetter, headerValue?.name, false);
                      result = result?.__zone_symbol__value ?? result;
                      settingsData = result.error;
                      validationMessage = [result.message];
                      status = ['Error'];
                    }
                  }
                  else {
                    if (this.disablePublishButton == true) {
                      this.addPublishStatus(context, false);
                    }
                  }
                }
                const values = inputStatus === 'Deleted' ? ['Deleted'] : inputStatus !== '' ? ['Changed'] : ['New Row'];
                const val = !!status ? status : values;
                const msg = !!validationMessage ? validationMessage : [''];
                inputStatusArray.push(val);
                inputMessages.push(msg);
              }
              // Handle this when add settings in loop
              settingsData = settingsData?.__zone_symbol__value ?? settingsData;
              settings.add(`${this.tableName}_error_status`, JSON.stringify({ errorRows: settingsData }));

              for (let headerIndex = 0; headerIndex < this.headers?.length; headerIndex++) {
                const header = this.headers[headerIndex];
                const colLetter = ExcelHelper.getColumnLetter(headerIndex);
                if ((!!header?.permission && header?.permission !== 'UPDATE' || header?.autoGenerated || header?.systemAttribute) && !["D", "B", "C", "E", "A", "F"].includes(colLetter)) {
                  const readCol = sheet.getRange(`${colLetter}:${colLetter}`);
                  readCol.format.fill.color = "#c5c5c5";
                }
              }

              inputFieldRange.values = inputStatusArray;
              inputMessageField.values = inputMessages;
            }
          }

          if (['CellDeleted', 'ColumnDeleted'].includes(event.changeType) || event.changeType == 'RangeEdited' && !event.details) {
            await context.sync();
          }

          if (event?.changeType === 'RangeEdited' && !!firstLetter && !!secondLetter && changedIndex <= this.currentSheetHeaders.length) {
            const range = sheet.getRange(event.address)
            let settingsData: any;
            let inputMessages: any = [];
            let status: any = [];
            let totalRows: number = 0;
            let columnRange = extractColumnsFromRange(changedCellAddress);
            const idColumn = sheet.getRange(`A${firstLetter[2]}:A${secondLetter[2]}`);
            idColumn.load("values");
            range.load('values');
            const cellAddress = `D${firstLetter[2]}:D${secondLetter[2]}`;
            const inputFieldRange = sheet.getRange(cellAddress);
            const inputMessageField = sheet.getRange(`E${firstLetter[2]}:E${secondLetter[2]}`);
            const rowRange = sheet.getRange(event.address);
            rowRange.format.fill.color = "#ffd3a9";
            inputFieldRange.load('values');
            inputMessageField.load('values');
            await context.sync();
            try {
              // Handle this function when drag the values
              if (readPermissionData === -1 && !["D", "B", "C", "E", "A", "F"].includes(columnLetter)) {
                const columnValues = range.values;
                totalRows = columnValues.length;

                for (let columnInx = 0; columnInx < changedColumnIndices.length; columnInx++) {
                  columnLetter = columnRange[columnInx];
                  const colIndex = changedColumnIndices[columnInx];
                  const values = columnValues.map((val: any) => val[columnInx]);
                  const headerItem = this.currentSheetHeaders[colIndex];
                  if (!!headerItem?.referencedTableId && headerItem?.totalElements < this.totalElementLimit) {
                    currentLookupData = await this.indexeddbService.getAllData('Lookup_' + headerItem.referencedTableId);
                  }

                  // Handle column validation and row status checked
                  for (let i = 0; i < values.length; i++) {
                    const row = rangeArray[i];
                    const address = columnLetter + row;
                    const value = values[i];
                    const colorRange = sheet.getRange(`D${row}`);
                    let inputStatusVal = inputFieldRange.values[i][0];
                    let idColumnVal = idColumn.values[i][0];

                    // Handle this when data validation matched
                    const valueMatch = await this.matchColumnValuesWithValidation(headerItem, value, currentLookupData);
                    let validationFailedRowByIndex = failedValidationRows?.errorRows?.findIndex((el: any) => el.row === row) ?? -1;
                    let columnRow = failedValidationRows?.errorRows?.find((el: any) => (el.row === row && el.column.includes(columnLetter)));
                    let permission = !!currentSheetPermission && currentSheetPermission.length > 0 ? currentSheetPermission[row - 2] : null;
                    permission = !permission || permission === 'UPDATE' ? headerItem?.permission : permission;
                    if ((!!permission && permission !== 'UPDATE') || ["D", "B", "C", "E", "A", "F"].includes(columnLetter) || changedIndex == this.currentSheetHeaders.length) {
                      // Handle this function when did't have permission to update
                      const message = (headerItem?.autoGenerated || headerItem?.permission !== 'UPDATE') ? 'permission' : 'validation';
                      let result: any = this.addColumnErrorValidations(sheet, address, message, row, settings, failedValidationRows, columnLetter, headerItem?.name, false);
                      result = result?.__zone_symbol__value ?? result;
                      settingsData = result.error;
                      inputMessages.push([result.message]);
                      status.push(['Error']);
                    }
                    else{
                      if (!!valueMatch && (validationFailedRowByIndex !== -1 && !!columnRow)) {
                        // Handle this for remove that particular row and column from the settings
                        const item: any = await this.updateSettings(sheet, settings, failedValidationRows, row, columnLetter, headerItem?.name, false);
                        validationFailedRowByIndex = item.errorRowByIndex;
                        settingsData = item.errorStatus;
                        validationFailedRowByIndex !== -1 ? status.push(['Error']) : '';
                      }
                      // Handle this when data validation failed
                      if (!valueMatch || headerItem?.autoGenerated) {
                        const message = (headerItem?.autoGenerated || headerItem?.permission !== 'UPDATE') ? 'permission' : 'validation';
                        let result: any = this.addColumnErrorValidations(sheet, address, message, row, settings, failedValidationRows, columnLetter, headerItem?.name, false);
                        result = result?.__zone_symbol__value ?? result;
                        settingsData = result.error;
                        inputMessages.push([result.message]);
                        status.push(['Error']);
                      }
                      else if (validationFailedRowByIndex === -1 && !headerItem?.autoGenerated) {
                        inputMessages.push(['']);
                        const values = inputStatusVal === 'Deleted' ? ['Deleted'] : (inputStatusVal === 'New Row' || idColumnVal === '') ? ['New Row'] : ['Changed'];
                        status.push(values);
                        colorRange.format.font.color = '#000000';
                      }
                      else if(!!valueMatch && validationFailedRowByIndex !== -1 && !columnRow) {
                        const permissionMsg = '- This attribute is read only. You do not have permission to update this attribute';
                        const validationMsg = '- This attribute is not valid.';
                        const message = failedValidationRows?.errorRows[validationFailedRowByIndex]?.columnName?.map((colName: any, index: number) => (failedValidationRows?.errorRows[validationFailedRowByIndex]?.type[index] === 'permission') ? `${colName} ${permissionMsg}` : `${colName} ${validationMsg}`).join('\n');
                        inputMessages.push([message]);
                        status.push(['Error']);
                      }
                    }
                  };
                };
              }
              else {
                const columnValues = range.values;
                totalRows = columnValues.length;
                for(let i=0; i<rangeArray.length; i++) {
                  const row = rangeArray[i];
                  let idColumnVal = idColumn.values[i][0];
                  const permission = !!currentSheetPermission && currentSheetPermission.length > 0 ? currentSheetPermission[row - 2] : null;
                  const colorRange = sheet.getRange(`D${row}`);
                  if ((!!permission && permission !== 'UPDATE') || ["D", "B", "C", "E", "A", "F"].includes(columnLetter) || changedIndex == this.currentSheetHeaders.length) {
                    // Handle this function when did't have permission to update
                    const address = columnLetter + row;
                    let result: any = await this.addColumnErrorValidations(sheet, address, 'permission', row, settings, failedValidationRows, columnLetter, headerItem?.name, false);
                    result = result?.__zone_symbol__value ?? result;
                    settingsData = result.error;
                    inputMessages.push([result.message]);
                    status.push(['Error']);
                  }
                  else {
                    const address = columnLetter + row;
                    const values = inputStatus === 'Deleted' ? ['Deleted'] : (inputStatus === 'New Row' || idColumnVal === '') ? ['New Row'] : ['Changed'];
                    status.push(values);
                    inputMessages.push(['']);
                    colorRange.format.font.color = '#000000';
                  }
                }
              }
              // Handle this when add settings in loop
              if (!!settingsData) {
                settingsData = settingsData?.__zone_symbol__value ?? settingsData;
                settings.add(`${this.tableName}_error_status`, JSON.stringify({ errorRows: settingsData }));
              }

              inputFieldRange.values = status.slice(`-${totalRows}`);
              inputMessageField.values = inputMessages.slice(`-${totalRows}`);
              await context.sync();
              if (this.disablePublishButton == true) {
                this.addPublishStatus(context, false);
              }
            } catch (error) {
              console.error('Error updating cell values:', error);
            }
          }
          if (!changedCellAddress.startsWith("Sheet1!A") && !!event.details && event?.changeType === 'RangeEdited' && changedIndex <= this.currentSheetHeaders.length) {
            let permission = !!currentSheetPermission && currentSheetPermission.length > 0 ? currentSheetPermission[rowNumber - 2] : null;
            permission = !permission || permission === 'UPDATE' ? headerItem?.permission : permission;
            const currentRange = sheet.getRange(event.address);
            currentRange.format.fill.color = "#ffd3a9";
            // Handle this for domain based attribute values match.
            const valueMatch = await this.matchColumnValuesWithValidation(headerItem, event?.details?.valueAfter, currentLookupData, currentRange);
            const readColumn = !!this.autoColumns ? this.autoColumns.includes(`${columnLetter}:${columnLetter}`) : null;
            if (((!!permission && permission !== 'UPDATE') || readColumn || changedIndex == this.currentSheetHeaders.length && inputStatus !== 'Deleted')) {
              // Handle this function when did't have permission
              const message = permission !== 'UPDATE' ? 'permission' : 'validation';
              await this.addColumnErrorValidations(sheet, event.address, message, rowNumber, settings, failedValidationRows, columnLetter, headerItem?.name, true);
            }
            else {
              // Handle this function when single column changed
              if (inputStatus !== 'Deleted') {
                // Handle this when failed rows match and column
                let validationFailedRowByIndex = failedValidationRows?.errorRows?.findIndex((el: any) => el.row === rowNumber) ?? -1;
                let columnRow = failedValidationRows?.errorRows?.find((el: any) => (el.row === rowNumber && el.column.includes(columnLetter)));
                if (!!valueMatch && (validationFailedRowByIndex !== -1 && !!columnRow)) {
                  // Handle this when value match then remove that particular row and column
                  const item: any = await this.updateSettings(sheet, settings, failedValidationRows, rowNumber, columnLetter, headerItem?.name, true);
                  validationFailedRowByIndex = item.errorRowByIndex;
                } else if (!valueMatch || headerItem?.autoGenerated || headerItem?.permission !== 'UPDATE') {
                  const message = headerItem?.autoGenerated ? 'permission' : 'validation';
                  this.addColumnErrorValidations(sheet, event.address, message, rowNumber, settings, failedValidationRows, columnLetter, headerItem?.name, true);
                }
                if (validationFailedRowByIndex === -1 && valueMatch && !headerItem?.autoGenerated) {
                  // Handle this for remove current row color and message.
                  inputMessgeValue.values = [['']];
                  hasChangedRow = true;
                  const val = (inputStatus === 'New Row' || idColumnVal === '') ? [['New Row']] : [['Changed']];
                  inputValue.values = val;
                  inputValue.getUsedRange().format.font.color = "#000000";
                  // var range = sheet.getRange("A" + rowNumber + ":XFD" + rowNumber);
                  currentRange.format.fill.color = "#ffd3a9";
                }
              };
            }
            if (this.disablePublishButton == true) {
              this.addPublishStatus(context, false);
            }
            await context.sync();
          }
          const columnERange = sheet.getRange('E:E').getUsedRange();
          columnERange.format.load("wrapText");
          columnERange.format.wrapText = false;
          await context.sync();
          changeDetectionEventInProgress = false;
          if (this.insertedRow.length == 1) {
            this.excelChangeEvent(this.insertedRow[0])
            this.insertedRow = [];
          }
          this.cd.detectChanges();
        }
        catch (err) {
          changeDetectionEventInProgress = false;
          console.log(err, 'changeevt')
        } finally {
          changeDetectionEventInProgress = false;
          context.runtime.enableEvents = true;
        }
        if (!!this.currentDialog) {
          this.currentDialog.close();
          this.loading = false;
          this.overLay = false;
          this.contentName = '';
          this.cd.detectChanges();
        }
        return context.sync();
      }).catch(function (error: any) {
        changeDetectionEventInProgress = false;
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
          console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
      });
    }
  }

  findLargestColumnIndex(addresses: string):any {
    if (!addresses) return -1;

    let columns: string[] = [];

    // Split the addresses by comma for multiple ranges
    let addressArray = addresses.split(',');

    addressArray.forEach((address) => {
      // Split ranges by colon
      let parts = address.split(':');
      parts.forEach((part) => {
        // Extract the letter part of the cell reference
        let column = part.match(/[A-Za-z]+/)?.[0];
        if (column) {
          columns.push(column);
        }
      });
    });

    // Find and return the lexicographically largest column
     let largestColumn = columns.sort((a, b) => (a > b ? 1 : -1)).pop() || '';
     return this.columnIndexFromLetter(largestColumn)
  }

  async addPublishStatus(context: any, status: boolean) {
    this.disablePublishButton = status;
    const settings = context.workbook.settings;
    settings.add(this.tableName + 'changes', status);
    await context.sync();
    this.cd.detectChanges();
  }

  /**
   * Handle this for validate the values
   * @param headerItem any
   * @param item any
   * @param currentLookupData any
   * @returns 
   */
  public async matchColumnValuesWithValidation(headerItem: any, item: any, currentLookupData: any, currentRange?: any) {
    if (headerItem?.formType === 'LOOKUP' && headerItem?.totalElements < this.totalElementLimit) {
      // Lookup attribute validation
      if (item !== '') {
        // let val = (typeof item === 'string' && item?.includes('{'))
        //   ? item?.split(/[\{\}]/).filter((el: any) => el !== "")
        //   : item;
        // val = !Array.isArray(val) ? item : val[0];

        // const valueMatch = currentLookupData?.find((el: any) => !!el.code ? el.code === val : el.name === val);
        // return valueMatch;
        // Extract name and code from val
        const match = item.toString().match(/^([^{}]+)(?:\{([^{}]+)\})?$/);

        if (!match) {
          return false; // The value does not match the expected format
        }
        const extractedCode = match[1];
        const extractedName = match[2] ? match[2].toString() : null;

        // Check if any entry in lookupData matches
        return currentLookupData.some((el: any) => {
          // Check if the name matches (if extractedName is not null)
          const isNameMatch = (extractedName === null) || (el.name === extractedName);

          // Check if the code matches
          const isCodeMatch = (el.key === extractedCode);

          // Return true only if both name and code match, or if only code matches and name is null
          return (extractedName === null && isCodeMatch) || (extractedName !== null && isNameMatch && isCodeMatch);
        })
      }
    }
    else if (headerItem?.formType === 'FREE_FORM') {
      // Number validation
      if (headerItem?.dataType === 'number' && item !== '') {
        const isNumber = typeof item;
        if (isNumber !== 'number') {
          return false;
        }
      }

      // Date validation
      if (headerItem?.dataType === 'date' && item !== '') {
        const isDate = this.validateDate(item, headerItem?.dateFormat, currentRange);
        return isDate;
      }
    }
    return true;
  }

  /**
   * Handle this for date validation
   * @param dateStr any
   * @param format string
   * @returns 
   */
  public validateDate(dateStr: any, format: string, currentRange?: any) {
    // Helper function to validate the date pattern
    function isPatternValid(dateStr: string, pattern: RegExp): boolean {
      return pattern.test(dateStr);
    }

    // Define regex patterns for different formats
    const patterns: any = {
      'MM/DD/YYYY': /^(0[1-9]|1[0-2]|[1-9])\/(0[1-9]|[12][0-9]|3[01]|[1-9])\/\d{4}$/,
      'DD/MM/YYYY': /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2]|[1-9])\/\d{4}$/,
      'YYYY/MM/DD': /^\d{4}\/(0[1-9]|1[0-2]|[1-9])\/(0[1-9]|[12][0-9]|3[01]|[1-9])$/,
    };

    // Handle numeric inputs
    if (typeof dateStr === 'number') {
      const milliseconds = (dateStr - 25569) * 86400 * 1000;
      dateStr = moment(milliseconds).utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(format);
    }

    // Determine the appropriate pattern based on the format
    const pattern = patterns[format];
    if (!pattern || !isPatternValid(dateStr, pattern)) {
      return false;
    }

    // Parse the date based on format
    let month, day, year;
    const parts = dateStr.split('/').map(Number);

    switch (format) {
      case 'MM/DD/YYYY':
        [month, day, year] = parts;
        break;
      case 'DD/MM/YYYY':
        [day, month, year] = parts;
        break;
      case 'YYYY/MM/DD':
        [year, month, day] = parts;
        break;
      default:
        return false;
    }

    // Validate the date object
    const date = new Date(year, month - 1, day);
    if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
      return false;
    }

    // Define the acceptable date range
    const startDate = new Date('1900-01-01');
    const endDate = new Date('9999-12-31');
    // !!currentRange ? currentRange.values = [[dateStr]] : '';

    return date >= startDate && date <= endDate;
  }

  /**
   * Handle this function for row permission and edit the columns
   * @param sheet any
   * @param address any
   * @param permissionMessage string
   * @param rowNumber any
   * @param columnIndex any
   * @param value any
   */
  public async addColumnErrorValidations(sheet: any, address: any, type: string, rowNumber: any, settings: any, settingsErrorValue: any, columnLetter: any, headerName: string, addSettings: boolean, value?: any) {
    const inputValue = sheet.getRange(`D${rowNumber}`);
    const inputMessageRange = sheet.getRange(`E${rowNumber}`);
    const currentColumnRange = sheet.getRange(address);
    inputValue.format.font.color = '#FF0000';
    currentColumnRange.format.fill.color = '#ffcecb';

    // Initialize errorRows if not already defined or if settingsErrorValue?.errorStatus is not an array
    let errorRows = Array.isArray(settingsErrorValue?.errorRows)
      ? settingsErrorValue.errorRows
      : [];
    let existingRow = errorRows?.find((el: any) => el.row === rowNumber);
    const existingColmn = existingRow?.column?.filter((el: any) => el === columnLetter);

    // Check if rowNumber is already in errorRows
    if (errorRows.length === 0 || !existingRow) {
      // Add rowNumber to errorRows if it's not already present
      errorRows.push({ row: rowNumber, column: [columnLetter], columnName: [headerName], type: [type] });
    }
    else if (existingRow && existingColmn.length === 0) {
      existingRow.column = [...existingRow.column, columnLetter];
      existingRow.columnName = [...existingRow.columnName, headerName];
      existingRow.type = [...existingRow.type, type];
    }
    errorRows = errorRows.length > 0 ? errorRows : rowNumber;
    const currentRow = errorRows?.find((el: any) => el.row === rowNumber);
    const permissionMsg = '- This attribute is read only. You do not have permission to update this attribute';
    const validationMsg = '- This attribute is not valid.';
    const message = !!currentRow ? `${currentRow?.columnName?.map((colName: any, index: number) => (currentRow?.type[index] === 'permission') ? `${colName} ${permissionMsg}` : `${colName} ${validationMsg}`).join('\n')}` : "";
    if (addSettings) {
      inputMessageRange.values = [[message]];
      inputValue.values = [['Error']];
      settings.add(`${this.tableName}_error_status`, JSON.stringify({ errorRows: errorRows }))
    }
    const result = { error: errorRows, message: message };
    return result;
  }


  columnIndexFromLetter(letter: string): number {
    letter = letter.toUpperCase();
    let index = 0;
    for (let i = 0; i < letter.length; i++) {
      index = index * 26 + (letter.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
    }
    return index;
  }
  async addToChangeColumn(existingValues: any, indices: any[], context: any, range: any, newOrDelete = false) {
    if (!newOrDelete) {
      existingValues.forEach((changes: any, index: number) => {
        let data: any = [];
        data = changes[0] == "" ? [] : JSON.parse(existingValues[index]);
        data.push(...indices)
        data = [...new Set(data)];
        existingValues[index] = [JSON.stringify(data)];
      });
      range.values = existingValues;
      await context.sync;
    }
  }
  public async logout() {
    await Excel.run(async (context: any) => {
      const settings = context.workbook.settings;
      settings.add('roles', this.roles)

    }).catch((error: any) => {
      console.error(error);
    });
    this.broadcastChannelService.closeBroadcastChannel();
    localStorage.clear();
    this.route.navigate(['/landing']);
  }

  public onSavedFilter() {
    let dialog: any;
    this.previousQuery = this.query
    Office.context.ui.displayDialogAsync(
      `${environment.URL}/#/savedFilter/${this.entity.id}`,
      { height: 80, width: 60 },
      (item) => {
        dialog = item.value;
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          async (arg: any) => {
            dialog.close();
            const res = JSON.parse(decodeURIComponent(arg?.message));
            if (arg?.message != 'close' && res?.type !== 'invalid-version') {
              this.savedFilter = JSON.parse(decodeURIComponent(arg?.message));
              this.query = this.savedFilter.filterQuery;
              this.isUpdate = true;
              setTimeout(() => {
                this.openDialogBox(this.entity);
              }, 100);
            }

            if (res?.type === 'invalid-version') {
              dialog.close()
              setTimeout(() => {
                this.handleMismatchVersionDialog(res?.message);
              }, 500);
            }
          });
      });
  }

  /**
   * Handle this function for add and edit the particular settings
   * @param settings any
   * @param errorStatus any
   * @param rowNumber number
   * @returns 
   */
  async updateSettings(sheet: any, settings: any, errorStatus: any, rowNumber: number, columnLetter: any, columnName: any, addSettings: boolean) {
    const inputMessageRange = sheet.getRange(`E${rowNumber}`);
    let existingRow = errorStatus?.errorRows?.find((elm: any) => elm.row === rowNumber);
    const removeColumn = existingRow?.column?.filter((el: any) => el !== columnLetter);
    const removeColumnName = existingRow?.columnName?.filter((el: any) => el !== columnName);
    if (removeColumn.length > 0) {
      existingRow.column = removeColumn;
      existingRow.columnName = removeColumnName;
    }
    else {
      errorStatus.errorRows = errorStatus?.errorRows?.filter((el: any) => el.row !== rowNumber);
    }
    // errorStatus.errorRows = errorStatus?.errorRows?.filter((el: any) => !(el.row === rowNumber && el.column === columnLetter));
    const errorRowByIndex = errorStatus?.errorRows?.findIndex((el: any) => el.row === rowNumber);
    const currentExistingRow = errorStatus?.errorRows?.find((elm: any) => elm.row === rowNumber);
    const permissionMsg = '- This attribute is read only. You do not have permission to update this attribute';
    const validationMsg = '- This attribute is not valid.';
    const message = !!currentExistingRow ? `${currentExistingRow?.columnName?.map((colName: any, index: number) => (currentExistingRow?.type[index] === 'permission') ? `${colName} ${permissionMsg}` : `${colName} ${validationMsg}`).join('\n')}` : '';
    inputMessageRange.values = [[message]];
    const result = { errorRowByIndex: errorRowByIndex, errorStatus: errorStatus.errorRows }
    addSettings ? settings.add(`${this.tableName}_error_status`, JSON.stringify({ errorRows: errorStatus.errorRows })) : '';
    return result;
  }

  async removeEventHandler() {
    await Excel.run(this.onChangedEventHandler.context, async (context: any) => {
      this.onChangedEventHandler.remove();
      await context.sync();
      this.onChangedEventHandler = null;
    });
  }

  async manageRowIdentifierRow(sheet: Excel.Worksheet): Promise<void> {
    const columnName = '__ROW_IDENTIFIER';
    const startRowIndex = 1;
    const columnIndex = this.headers.findIndex((header: any) => header.name === columnName);

    if (columnIndex !== -1 && columnIndex < this.headers.length && this.headers[columnIndex].name === '__ROW_IDENTIFIER') {
      const formula = `=ROW()`;

      // Apply formula '=ROW()' to the column in the Excel sheet
      const columnRange = sheet.getRangeByIndexes(startRowIndex, columnIndex, 1, 1);
      columnRange.formulas = [[formula]];
      columnRange.load(['formulas']);
    }
  }

  private async tryCatchWrapper(callback: Function, message?: string) {
    try {
      if (callback) {
        await callback();
      }
    } catch (e) {
      if (message) {
        console.error(message);
      }
    }
  }

  async onSubmit() {
    //   await Excel.run(async (context: any) => {
    //     context.workbook.close(Excel.CloseBehavior.skipSave);
    // });
    window.location.reload();
  }

  public handleOfflineDialog() {
    this.unsubscribeNetwork();
    this.contentName = '';
    this.indexeddbService.closeDb();
    this.cd.detectChanges();
    if (!!this.intervalId) {
      clearInterval(this.intervalId)
    }
    this.clearMessage();
    hasChangedRow = false;
    Office.context.ui.displayDialogAsync(
      `${environment.URL}/#/missing/offline`,
      { height: 40, width: 40 },
      (item) => {
        const dialog = item.value;
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          async (arg: any) => {
            if (arg?.message == 'continue' && navigator.onLine) {
              dialog.close();
              setTimeout(() => {
                this.refreshEntityRecords();
              }, 500)
            }
          });
      });
  }

  openSettings() {
    Office.context.ui.displayDialogAsync(
      `${environment.URL}/#/settings`,
      { height: 60, width: 25 },
      (item) => {
        const dialog = item.value;
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          async (arg: any) => {
            if (arg?.message === 'send') {
              const data = {globalSettings: this.globalSettings, currentEntity: this.entity};
              setTimeout(() => {
                dialog.messageChild(JSON.stringify(data));
              }, 400)
            }
            else {
              dialog.close();
              if (arg?.message !== 'close') {
                this.globalSettings = JSON.parse(arg.message);
                this.showSytsemAttribute = this.globalSettings.settingsData.showSystem;
                this.showOrHideSystemAtrributes();
              }
            }
          }
        )
      }
    )
  }

  async showOrHideSystemAtrributes() {
    await Excel.run(async (context: any) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const headers = this.currentSheetHeaders ? this.currentSheetHeaders : this.headers;
      headers?.forEach((item: any, index: any) => {
        const columnLetter = ExcelHelper.getColumnLetter(index);
        if (!!columnLetter && item.systemAttribute && item.showInForm && item.name !== 'id') {
          sheet.getRange(`${columnLetter}:${columnLetter}`).columnHidden = !this.showSytsemAttribute;
        }
      });
      await context.sync();
    });
  }
}

class ExcelHelper {
  static getColumnLetter(columnIndex: number): string {
    let dividend = columnIndex + 1;
    let columnName = '';
    let modulo;

    while (dividend > 0) {
      modulo = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + modulo) + columnName;
      dividend = Math.floor((dividend - modulo) / 26);
    }

    return columnName;
  }
}

// Handle this function for getting the column range
function extractColumnsFromRange(range: any) {
  // Regular expression to match the start and end of the range
  const rangeRegex = /([A-Z]+)(\d+):([A-Z]+)(\d+)/;

  // Match the range string against the regex
  const match = range.match(rangeRegex);

  if (match) {
    // Extract start and end columns and rows from the regex match
    const startColumn = match[1];
    const endColumn = match[3];
    const startRow = parseInt(match[2], 10);
    const endRow = parseInt(match[4], 10);

    // Function to convert column letters to a number
    function columnToNumber(col: any) {
      let num = 0;
      for (let i = 0; i < col.length; i++) {
        num = num * 26 + (col.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
      }
      return num;
    }

    // Function to convert a number to column letters
    function numberToColumn(num: any) {
      let col = '';
      while (num > 0) {
        num--;
        col = String.fromCharCode(num % 26 + 'A'.charCodeAt(0)) + col;
        num = Math.floor(num / 26);
      }
      return col;
    }

    // Get the numeric values of the start and end columns
    const startColNum = columnToNumber(startColumn);
    const endColNum = columnToNumber(endColumn);

    // Create an array of columns in the range
    const columnsInRange = [];
    for (let colNum = startColNum; colNum <= endColNum; colNum++) {
      columnsInRange.push(numberToColumn(colNum));
    }

    return columnsInRange;
  } else {
    throw new Error('Invalid range format');
  }
}

function generateRange(start: any, end: any) {
  const range = [];
  for (let i = start; i <= end; i++) {
    range.push(i);
  }
  return range;
}

// Handle this function when get promises result then map the result
async function extractValuesFromZoneAwarePromises(promises: any) {
  // Wait for all promises to resolve
  const resolvedPromises = await Promise.all(promises);

  // Extract values from each resolved promise
  const extractedValues = resolvedPromises.map(promise => {
    // Handle the extraction from the promise result
    // Check if the result is an object with __zone_symbol__value property
    if (promise && promise.__zone_symbol__value) {
      return promise.__zone_symbol__value;
    }
    // If not, return the result directly
    return [promise];
  });

  // Flatten the array if necessary
  return extractedValues.flat();
}

function extractColumnReferences(cellRange: any) {
  // Check if the cellRange includes a colon (indicating a range)
  const firstPart = cellRange.includes(':') ? cellRange.split(':')[0] : cellRange;

  // Extract column letters (e.g., "I" from "I24")
  const columnLetters = firstPart.replace(/[^A-Z]/g, '');

  // Return combined column letters and row numbers
  return `${columnLetters}`;
}

function parseRowNumbers(address: any) {
  // Extract the numeric part(s) from the address using regex
  const matches = address.match(/\d+/g);

  if (!matches) {
    return null; // Handle cases where no numeric part is found
  }

  // Parse the numeric part(s) into integers
  const rowNumbers = matches.map((num: any) => parseInt(num));

  // If there's only one number, duplicate it to create [num, num]
  if (rowNumbers.length === 1) {
    return [rowNumbers[0], rowNumbers[0]];
  } else if (rowNumbers.length === 2) {
    // Ensure the first number is less than or equal to the second number
    return [Math.min(rowNumbers[0], rowNumbers[1]), Math.max(rowNumbers[0], rowNumbers[1])];
  } else {
    return null; // Handle unexpected cases where more than two numbers are found
  }
}

function areRowsSequential(arr: string[]): boolean {
  if (arr.length === 1) {
    return true; // Single cell is considered sequential
  }
  // Extract and sort row numbers from cell references
  let rowNumbers = arr.map((cell: any) => parseInt(cell.match(/\d+/)[0])).sort((a, b) => a - b);

  // Check if row numbers are sequential
  for (let i = 1; i < rowNumbers.length; i++) {
    if (rowNumbers[i] !== rowNumbers[i - 1] + 1) {
      return false;
    }
  }
  return true;
}

function convertCellsToRange(cellList: any, rowSequential: boolean) {
  // Split the input string into an array of cell references
  let cells = cellList.split(',');

  // Initialize variables to find min and max row numbers and column letters
  let minRow = Number.MAX_SAFE_INTEGER;
  let maxRow = 0;
  let startColumn = '';
  let endColumn = '';

  // Iterate through each cell reference
  if (cells.length > 1 && rowSequential) {
    cells.forEach((cell: any) => {
      // Extract row number and column letter from the cell reference
      let match = cell.match(/([A-Z]+)(\d+)/);
      if (match) {
        let colLetter = match[1];
        let rowNumber = parseInt(match[2]);

        // Determine the minimum and maximum row numbers
        if (rowNumber < minRow) {
          minRow = rowNumber;
        }
        if (rowNumber > maxRow) {
          maxRow = rowNumber;
        }

        // Take the column letter (assuming all cells are in the same column)
        if (startColumn === '') {
          startColumn = colLetter;
        }
        // Always update the endColumn to ensure the last column is taken
        endColumn = colLetter;
      }
    });

    // Construct the range string
    let range = startColumn + minRow + ':' + endColumn + maxRow;
    return range;
  } else {
    return cells
  }
}

function arraysEqual(arr1: any[], arr2: any[], headers: any): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

