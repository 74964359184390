import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  api_url = environment.AUTH_URL;
  constructor(private http: HttpClient,
  ) { }

  getToken(): Observable<string> {
    return this.tokenSubject.asObservable();
  }

  updateToken(newToken: string): void {
    this.tokenSubject.next(newToken);
  }
  login(username:string,password:string)
  {
    const payload = new URLSearchParams()
    payload.set('grant_type', 'password')
    payload.set('client_id', environment.CLIENT)
    payload.set('username', username)
    payload.set('password', password)
    return this.http.post(`${this.api_url}/realms/${environment.REALMS}/protocol/openid-connect/token`,payload.toString());
  }
  isLoggedIn() {
    try {
      const token = localStorage.getItem('token'); // get token from local storage
      if (!!token) {
        const payload = atob(token.split('.')?.[1]); // decode payload of token
        const parsedPayload = JSON.parse(payload); // convert payload into an Object
    
        return parsedPayload.exp > Date.now() / 1000; // check if token is expired}
      }
      else {
        return false
      }
    } catch(e) {
      console.log('Error: ', e);
      return false;
    }
  }
}
