import { Component, ViewChild } from '@angular/core';
import { SettingsService } from './service/settings.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  @ViewChild("all") private all!: MatOption;
  public notifier$: Subject<boolean> = new Subject();
  showSystem = false;
  globalSettings: any;
  currentEntity: any;
  currentSettings: any;
  noOfRecords: number = environment.RECORD_LIMIT;
  filteredlList: any = [];
  initialData: any;
  columnForm!: FormGroup;
  loading = true;
  constructor(
    private settingsService: SettingsService,
    private fb: FormBuilder
  ) {
    this.columnForm = this.fb.group({
      column: new FormControl(""),
      search: new FormControl(""),
    });
    Office.context.ui.messageParent('send');
    Office.context.ui.addHandlerAsync(Office.EventType.DialogParentMessageReceived, async (arg: any) => {
      const data = JSON.parse(arg.message);
      console.log(data, 'res');
      this.globalSettings = data?.globalSettings;
      this.currentEntity = data?.currentEntity;
      this.showSystem = this.globalSettings.settingsData.showSystem;
      this.filteredlList = this.currentEntity?.attributes.filter((el: any) => !['name', 'code'].includes(el.name) && !el.systemAttribute);
      this.initialData = this.filteredlList;
      // Handle this function for get all settings 
      await this.getCurrentEntitySettings();
    })
  }

  async ngOnInit() {
    this.columnForm.controls["search"].valueChanges.pipe(takeUntil(this.notifier$),).subscribe(() => {
      this.filterSelection();
    });
  }

  /**
   * Handle this function for get all the current settings
   * @returns 
   */
  async getCurrentEntitySettings(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.settingsService
        .getCurrentSettings(this.currentEntity)
        .subscribe((results: any) => {
          console.log(results, 'currentity')
          if (results?.settingsData) {
            this.currentSettings = results;
            this.noOfRecords =
              (results?.settingsData?.noOfRecords && results?.settingsData?.noOfRecords !== 0) ? results?.settingsData?.noOfRecords : this.noOfRecords;
            if (
              results.settingsData.columns !== null &&
              results.settingsData.columns?.length !== 0
            ) {
              this.columnForm.controls["column"].patchValue(
                results.settingsData.columns
              );
            } else {
              this.columnForm.controls["column"].patchValue([
                ...this.filteredlList.map((item: any) => item.name),
                "All",
              ]);
            }
          } else {
            this.columnForm.controls["column"].patchValue([
              ...this.filteredlList.map((item: any) => item.name),
              "All",
            ]);
          }
          this.loading = false;
        });
    });
  }

  /**
   * Handle this function for apply the current settings
   */
  public applySettings() {
    this.loading = true;
    console.log(this.globalSettings)
    this.globalSettings.settingsData.showSystem = this.showSystem;

    let global = this.globalSettings;
    let current = this.currentSettings;
    let data = ["name", "code"];
    let selected = this.columnForm.controls["column"].value;
    data.push(...selected, "comment");
    data = [...new Set(data)];
    let item = this.columnForm.controls["column"].value.find((item: any) => item === 'All')
    if (item === "All") {
      data = [];
    }
    if (this.currentSettings?.id) {
      this.currentSettings = {
        id: this.currentSettings.id,
        entityId: this.currentEntity.id,
        groupId: this.currentEntity.groupId,
        settingsData: {
          noOfRecords: this.noOfRecords,
          columns: data,
          showSystem: this.showSystem,
        },
        type: "entity",
      };
      if (
        current?.settingsData.noOfRecords !==
        this.currentSettings?.settingsData.noOfRecords ||
        current?.settingsData.freeze !==
        this.currentSettings?.settingsData.freeze ||
        JSON.stringify(current?.settingsData.columns) !==
        JSON.stringify(this.currentSettings?.settingsData.columns)
      ) {
        this.settingsService
          .putSettings(this.currentSettings)
          .subscribe((result: any) => { });
      }
    } else {
      this.currentSettings = {
        entityId: this.currentEntity.id,
        groupId: this.currentEntity.groupId,
        settingsData: {
          noOfRecords: this.noOfRecords,
          columns: data,
        },
        type: "entity",
      };

      if (
        current?.settingsData.noOfRecords !==
        this.currentSettings?.settingsData.noOfRecords ||
        current?.settingsData.freeze !==
        this.currentSettings?.settingsData.freeze ||
        JSON.stringify(current?.settingsData.columns) !==
        JSON.stringify(this.currentSettings?.settingsData.columns)
      ) {
        this.settingsService
          .setSettings(this.currentSettings)
          .subscribe((result: any) => { });
      }
    }

    if (this.globalSettings?.id) {
      this.settingsService
        .putSettings(this.globalSettings)
        .subscribe((result: any) => {
          this.globalSettings = result;
          Office.context.ui.messageParent(JSON.stringify(this.globalSettings));
        });
    }
    else {
      this.settingsService
        .setSettings(this.globalSettings)
        .subscribe((result: any) => {
          this.globalSettings = result;
          Office.context.ui.messageParent(JSON.stringify(this.globalSettings));
        });
    }
  }

  filterSelection() {
    let text = this.columnForm.controls["search"].value;
    this.filteredlList = this.initialData.filter((item: any) => {
      return (
        item.name.trim().toLowerCase().indexOf(text.trim().toLowerCase()) != -1
      );
    });
  }

  /**
   * Handle this function for select and deselect all values
   */
  public toggleAll() {
    if (this.all.selected) {
      this.columnForm.controls["column"].patchValue([
        ...this.filteredlList.map((item: any) => item.name),
        "All",
      ]);
    } else {
      this.columnForm.controls["column"].patchValue([]);
    }
  }

  /**
   * Handle this function for select and deselect the value
   * @returns 
   */
  public togglePerColumn() {
    if (this.all.selected) {
      this.all.deselect();
      return false;
    }
    if (
      this.columnForm.controls["column"].value.length == this.initialData.length
    )
      this.all.select();
    return;
  }

  onCancel() {
    Office.context.ui.messageParent('close');
  }

   /**
   * The ngOnDestroy lifecycle hook in Angular is used to perform cleanup tasks when a component is about to be destroyed.
     It is called just before the component is removed from the DOM.
   */
     ngOnDestroy() {
      this.notifier$.next(true);
      this.notifier$.complete();
    }
}
