<div class="container">
    <!-- <div *ngIf="!data.edit" class="header">Filter</div>
    <div *ngIf="data.edit"  class="filtername" >{{data.filterName}}<span class="ml-4 " *ngIf="edited">Edited</span></div> -->
    <div class="body">
      <form [formGroup]="form">
        <query-builder class="filter-query" [config]="config" formControlName="query" [allowCollapse]="true" [allowRuleset]="true"
          [disabled]="data.disabled || false" [persistValueOnFieldChange]="data.persistValueOnFieldChange || true"
          [emptyMessage]="data.emptyMessage || ''" #queryBuilder>
          <ng-container *queryButtonGroup="
            let ruleset;
            let addRule = addRule;
            let addRuleSet = addRuleSet;
            let removeRuleSet = removeRuleSet;
          ">
            <div class="flex flex-row">
              <button mat-raised-button *ngIf="addRuleSet" title="Rule" [ngClass]="'rule-button'"
                (click)="handleAddRule(ruleset, addRule);" class="ml-2">
                Rule
              </button>
              <button mat-raised-button *ngIf="addRuleSet" title="Rule set" [ngClass]="'rule-button'" (click)="addRuleSet()"
                class="ml-2">
                Rule set
              </button>
  
              <button *ngIf="removeRuleSet" (click)="removeRuleSet()"
                class="ml-2 h-full text-r-primary flex flex-row cursor-pointer p-2 border-r-primary-light border-solid">
                <mat-icon class="mt-auto">delete</mat-icon>
              </button>
            </div>
          </ng-container>
  
          <ng-container *queryArrowIcon>
            <mat-icon ngClass="mat-arrow-icon">chevron_right</mat-icon>
          </ng-container>
  
          <ng-container *queryRemoveButton="let rule; let removeRule = removeRule">
            <button (click)="removeRule(rule)"
              class="ml-2 h-full text-r-primary flex flex-row cursor-pointer p-2 border-r-primary-light border-solid">
              <mat-icon class="mt-auto">delete</mat-icon>
            </button>
          </ng-container>
  
          <ng-container *queryField="let rule; let fields=fields; let onChange=onChange">
            <mat-form-field class="ml-2 filter-mat-form" appearance="outline">
              <mat-select [panelClass]="'entity-dropdown'" [ngModelOptions]="{standalone:true}" [(ngModel)]="rule.field"
                (valueChange)="handleLookupData($event,rule)" (ngModelChange)="onChange($event, rule)">
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" [ngModelOptions]="{standalone:true}" ngModel
                    (ngModelChange)="handleSerch($event, rule, 'fieldSearch')"></ngx-mat-select-search>
                </mat-option>
                <mat-option value="" class="option">--Select--</mat-option>
                <mat-option *ngFor="let field of rule.filteredItems" [value]="field.value">{{field.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
  
          <ng-container *queryOperator="let rule; let operators=operators; let onChange=onChange">
            <mat-form-field class="mx-4 filter-mat-form" appearance="outline">
              <mat-select [panelClass]="'entity-dropdown'" *ngIf="operators?.length > 0" [ngModelOptions]="{standalone:true}" [(ngModel)]="rule.operator"
                (ngModelChange)="handleOperators($event, rule, onChange)">
                <mat-option *ngFor="let value of operators" [value]="value">{{value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
  
          <ng-container *queryInput="let rule; let field=field; let onChange=onChange; type: 'LOOKUP'; ">
            <ng-container *ngIf="field?.count <= pageSize">
              <mat-form-field class="filter-mat-form" appearance="outline" *ngIf="!rule?.isMultiSelect; else multiSelectDrodown">
                <mat-select [ngModelOptions]="{standalone:true}" [(ngModel)]="rule.value"
                  (ngModelChange)="onChange($event, rule)" (valueChange)="addLookupInfo(rule, field,$event)">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" [ngModelOptions]="{standalone:true}" ngModel
                      (ngModelChange)="handleSerch($event, rule)"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option value="" class="option">--Select--</mat-option>
                  <mat-option *ngFor="let opt of rule?.filteredOptions" [value]="opt.id">
                    {{getDisplayProp(opt)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="field?.count > pageSize">
              <mat-form-field class="filter-mat-form" appearance="outline">
                <input matInput [style.display]="'none'" [ngModelOptions]="{ standalone: true }" [(ngModel)]="rule.value"
                  (ngModelChange)="onChange($event,rule)">
                <input matInput [value]="rule?.valueName" [readonly]="true">
              </mat-form-field>
              <button mat-raised-button *ngIf="field?.count > pageSize" color="primary" class="edit-button ml-2" title="selectItem"
                (click)="openPopup(rule, onChange)">
                <mat-icon>edit</mat-icon>
              </button>
            </ng-container>

             <!-- Handle this for mat multi select dropdown -->
          <ng-template #multiSelectDrodown>
            <mat-form-field class="filter-mat-form" appearance="outline">
              <mat-select [ngModelOptions]="{standalone:true}" [(ngModel)]="rule.value"
                (ngModelChange)="onChange($event, rule)" multiple>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" [ngModelOptions]="{standalone:true}" ngModel
                    (ngModelChange)="handleSerch($event, rule)"></ngx-mat-select-search>
                </mat-option>
                <mat-option class="option" (click)="toggleSelectAll(rule, field?.filteredOptions, rule?.isMultiSelect, all?.selected)" #all value="All">Select All</mat-option>
                <mat-option *ngFor="let opt of rule?.filteredOptions" [value]="opt.id" (click)="togglePerValue(field?.filteredOptions, rule, all)">
                  {{getDisplayProp(opt)}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>
          </ng-container>
  
          <ng-container *queryInput="let rule; let field=field; let onChange=onChange; type: 'boolean'; ">
            <mat-form-field class="filter-mat-form" appearance="outline">
              <mat-select [panelClass]="'entity-dropdown'" [ngModelOptions]="{standalone:true}" [(ngModel)]="rule.value"
                (ngModelChange)="onChange($event, rule)">
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" [ngModelOptions]="{standalone:true}" ngModel
                    (ngModelChange)="handleSerch($event, rule)"></ngx-mat-select-search>
                </mat-option>
                <mat-option value="" class="option">--Select--</mat-option>
                <mat-option *ngFor="let opt of field?.filteredOptions" [value]="opt.value">
                  {{opt.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

           <!-- Handle this for string input -->
          <ng-container *queryInput="let rule; let field=field; let onChange=onChange; type: 'string'; ">
            <ng-container *ngIf="!rule?.isMultiSelect; else multiInput">
              <mat-form-field class="filter-mat-form" appearance="outline">
                <input [ngModelOptions]="{standalone:true}" [(ngModel)]="rule.value"
                (ngModelChange)="onChange($event, rule)" matInput type="text" />
              </mat-form-field>
            </ng-container>
            <ng-template #multiInput>
              <ng-container *ngTemplateOutlet="multiTagsInput; context: {rule: rule, onChange: onChange}"></ng-container>
            </ng-template>
          </ng-container>

            <!-- Handle this for number input -->
          <ng-container *queryInput="let rule; let field=field; let onChange=onChange; type: 'number'; ">
            <ng-container *ngIf="!rule?.isMultiSelect; else multiInput">
              <mat-form-field class="filter-mat-form" appearance="outline">
                <input [ngModelOptions]="{standalone:true}" [(ngModel)]="rule.value"
                (ngModelChange)="onChange($event, rule)" matInput [type]="rule?.type" />
              </mat-form-field>
            </ng-container>
            <ng-template #multiInput>
              <ng-container *ngTemplateOutlet="multiTagsInput; context: {rule: rule, onChange: onChange}"></ng-container>
            </ng-template>
          </ng-container>
  
  
          <ng-container *queryInput="let rule; let field=field; let onChange=onChange; type: 'date'; ">
            <ng-container *ngIf="!rule?.isMultiSelect; else multiInput">
              <mat-form-field class="filter-mat-form" appearance="outline">
                <input [ngModelOptions]="{standalone:true}" [(ngModel)]="rule.value" (ngModelChange)="onChange($event, rule)"
                  matInput [matDatepicker]="picker" (dateChange)="rule.value=changeTimeStamp(rule.value)">
                <!-- <mat-label>MM/DD/YYYY</mat-label> -->
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </ng-container>
            <ng-template #multiInput>
              <ng-container *ngTemplateOutlet="multiTagsInput; context: {rule: rule, onChange: onChange}"></ng-container>
            </ng-template>
          </ng-container>
  
  
          <!-- <input  *ngIf="field.lookupItem && field.lookupItem.code" matInput [ngModelOptions]="{standalone:true}" [(ngModel)]="rule.value" (ngModelChange)="onChange($event, rule)"/> -->
  
          <!-- <ng-container *querySwitchGroup="let ruleset; let onChange = onChange">
          <mat-radio-group
            *ngIf="ruleset"
            [(ngModel)]="ruleset.condition"
            (ngModelChange)="onChange($event)"
          >
            <mat-radio-button [style.padding.px]="10" value="and"
              >And</mat-radio-button
            >
            <mat-radio-button [style.padding.px]="10" value="or"
              >Or</mat-radio-button
            >
          </mat-radio-group>
        </ng-container> -->
          <ng-container *querySwitchGroup="let ruleset; let onChange = onChange">
            <div *ngIf="ruleset" class="switch-container">
              <button mat-button class="square-button" [ngClass]="{ active: ruleset.condition === 'and' }"
                (click)="onChange('and')">
                And
              </button>
              <button mat-button class="square-button" [ngClass]="{ active: ruleset.condition === 'or' }"
                (click)="onChange('or')">
                Or
              </button>
            </div>
          </ng-container>
  
  
          <!-- <ng-container
          *queryInput="let rule; type: 'boolean'; let onChange = onChange"
        >
          <mat-checkbox
            [(ngModel)]="rule.value"
            (ngModelChange)="onChange()"
          ></mat-checkbox>
        </ng-container> -->
        </query-builder>
      </form>
      <div class="footer" *ngIf="dataLength > 0">
        <button [disabled]="!form.valid" mat-raised-button [ngClass]="!form.valid ? '' : 'rule-button'" (click)="applyFilter()" class="ml-2 btn">
          Apply Filter
        </button>
        <button mat-raised-button type='button' (click)="onCancel()" class="ml-2 cancel-btn">
          Cancel
        </button>
    </div>
      <!-- <button *ngIf="data.save && !data.edit" [disabled]="!form.valid" mat-raised-button color="primary" (click)="onApply('save')"
        class="ml-2">
        Save
      </button>
      <button *ngIf="data.save && data.edit && edited" mat-raised-button color="primary" class="ml-2" [matMenuTriggerFor]="menu"><mat-icon>keyboard_arrow_down</mat-icon></button>
      <button *ngIf="data.save && data.edit && edited" [disabled]="!form.valid" mat-raised-button color="primary" (click)="onApply('saveExisting')" class="ml-2">
        Save
      </button>
      <button *ngIf="data.save && data.edit && !edited" [disabled]="!form.valid" mat-raised-button color="primary" (click)="onApply('save')" class="ml-2">
        Save As
      </button>
     <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onApply('save')">Save As</button>
    <button mat-menu-item (click)="onCancel()">Discard Changes</button>
  </mat-menu> -->
      <!-- <button mat-raised-button type='button' (click)="onCancel()" class="ml-2">
        Cancel
      </button> -->
    </div>
  </div>


  <ng-template #multiTagsInput let-rule="rule" let-onChange="onChange">
    <mat-form-field class="filter-mat-form" appearance="outline">
      <input matInput [style.display]="'none'" [ngModelOptions]="{ standalone: true }" [(ngModel)]="rule.value"
        (ngModelChange)="onChange($event,rule)">
      <input matInput [value]="rule?.valueName" [readonly]="true">
    </mat-form-field>
    <button mat-raised-button color="primary" class="edit-button ml-2" title="selectItem"
      (click)="openMultiInputDialog(rule, onChange)">
      <mat-icon>edit</mat-icon>
    </button>
  </ng-template>