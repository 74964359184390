import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntityComponent } from './components/entity/entity.component';
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component';
import { EntityService } from './services/entity.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { TokenAuthComponent } from './components/token-auth/token-auth.component';
import { AddHeaderInterceptor } from './components/interceptor/header.interceptor';
import { RequestUtilityService } from './services/utility/request-utility.service';
import { FilterConditionsComponent } from './shared/filter-conditions/filter-conditions.component';
import { AddAnnotationComponent } from './components/add-annotation/add-annotation.component';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { LoginDialogueComponent } from './components/login-dialogue/login-dialogue.component';
import { InputMultiTagComponent } from './shared/input-multi-tag/input-multi-tag.component';

@NgModule({
  declarations: [
    AppComponent,
    TokenAuthComponent,
    LoginComponent,
    LoginDialogueComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    EntityComponent,
    FilterDialogComponent,
    HttpClientModule,
    SharedModule,
    EntityComponent,
    AddAnnotationComponent,
    FormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true
    },
    RequestUtilityService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    EntityService,
    EntityComponent,
    FilterConditionsComponent,
    InputMultiTagComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
