import { CommonModule } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ColumnMode, DatatableComponent, NgxDatatableModule, SelectionType, SortType } from '@swimlane/ngx-datatable';
import { Subject, timer } from 'rxjs';
import { FilterDialogComponent } from 'src/app/components/filter-dialog/filter-dialog.component';
import { EntityService } from 'src/app/services/entity.service';
import { FilterConditionsComponent } from '../filter-conditions/filter-conditions.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
const TIMER_CONST = 50;

@Component({
  selector: 'app-lookup-dialog',
  standalone: true,
  imports: [CommonModule, NgxDatatableModule, MatButtonModule, MatIconModule, MatCheckboxModule, FormsModule],
  templateUrl: './lookup-dialog.component.html',
  styleUrls: ['./lookup-dialog.component.scss']
})
export class LookupDialogComponent {
  @ViewChild(DatatableComponent)
  table!: DatatableComponent;
  columnMode: ColumnMode = ColumnMode.force;
  private dialogEventSubject = new Subject<any>();
  dialogEvent$ = this.dialogEventSubject.asObservable();
  count: any;
  offset: number = 0;
  item: any;
  pageSize: any;
  query?: any;
  pageNumber: any;
  filters: any;
  selected = [];
  selectedItem: any;
  isMultiSelect: boolean = false;
  currentSelectingItems: any[] = [];
  rows: any = [];
  selectionType: SelectionType = SelectionType.single;
  sortType = SortType.single;
  SelectionType = SelectionType;
  att: any;
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<LookupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private entityService: EntityService
  ) { }
  public headers: any[] = [
    // {
    //   column: "id",
    //   name: "Id",
    //   canAutoResize: true,
    //   isSortable: true,
    //   width: 100
    // },
    {
      column: "code",
      name: "Code",
      canAutoResize: true,
      isSortable: true,
      width: 200,
    },
    {
      column: "name",
      name: "Name",
      canAutoResize: true,
      isSortable: true,
      width: 200,
    },
  ];

  ngOnInit() {
    const selectionTimer = timer(TIMER_CONST);
    if (this.data) {
      // Since the form not completely get loaded by the time data arrived.
      // selectionTimer.subscribe(() => {
      this.item = this.data;
      this.isMultiSelect = this.item?.isMultiSelect;
      this.count = this.item.total;
      this.pageSize = this.item.pageSize;
      this.selectedItem = this.item.selectedItem;
      this.currentSelectingItems = this.isMultiSelect && Array.isArray(this.item?.selectedItem) ? this.item?.selectedItem : [];
      this.rows = this.item.value;
      if(this.isMultiSelect){
        this.updateSelectingRows();
      }
      this.entityService.load(this.item.lookupId).subscribe((item: any) => {
        this.att = item.attributes.filter((item: any) => {
          return item.name == "code" || item.name == "name";
        });
      });
      // });
    }
    setTimeout(() => {
      this.table.recalculate();
    }, 600);
  }

  public onItemSelected(event: any) {
    this.selectedItem = !this.isMultiSelect ? event.selected[0] : this.selectedItem;
  }
  public onSubmit() {
    this.selectedItem = this.isMultiSelect ? this.currentSelectingItems : this.selectedItem;
    this.dialogRef.close({ event: "Update", data: this.selectedItem });
    this.dialogEventSubject.next({ pageNubmer: this.pageNumber, filters: [],sorters:[{ direction: "ASC", property: "code" }]
  });
  }
  onPage(e: any) {
    this.pageNumber = e.offset;
    this.dialogEventSubject.next({
      pageNumber: this.pageNumber,
      filters: this.filters,
      sorters:[{ direction: "ASC", property: "code" }]
    });
  }
  upDatedData(newData: any) {
    this.rows = newData.value;
    if(this.isMultiSelect) {
      this.updateSelectingRows();
    }
    this.count = newData.total;
    this.selectedItem = !!newData?.selectedItem
      ? newData.selectedItem
      : this.selectedItem;
  }
  onFilter() {
    const dialogResult = this.dialog.open(FilterConditionsComponent, {
      width: "700px",
      height: "400px",
      data: {
        persistValueOnFieldChange: true,
        columns: this.getFilterColumns(),
        emptyMessage: "Please select filter criteria.",
        config: null,
        query: this.query
      },
      hasBackdrop: false
    });
    dialogResult.afterClosed().subscribe((val: any) => {
      this.query = dialogResult.componentInstance.data.query;
      this.filters = val;
      this.dialogEventSubject.next({
        pageNubmer: this.pageNumber,
        filters: this.filters,
        sorters:[{ direction: "ASC", property: "code" }]
      });
    });
  }

  onClearFilter() {
    this.query = null;
    this.filters = [];
    this.dialogEventSubject.next({
      pageNubmer: this.pageNumber,
      filters: this.filters,
      sorters:[{ direction: "ASC", property: "code" }]
    });
  }

  // Handle selecting a single row
  onSelect(row: any) {
    if (row.selected) {
      this.currentSelectingItems.push(row);
    } else {
      const index = this.currentSelectingItems.findIndex(({id}) => id === row?.id);
      if (index > -1) {
        this.currentSelectingItems.splice(index, 1);
      }
    }
  }

  // Toggle selecting/deselecting all rows
  toggleSelectAll() {
    // If all current page rows are selected, deselect them
    if (this.rows.every((row: any) => row.selected)) {
      this.rows.forEach((row: any) => {
        row.selected = false;
        const index = this.currentSelectingItems.findIndex(({id}) => id === row.id);
        if (index > -1) {
          this.currentSelectingItems.splice(index, 1);  // Remove from selected items
        }
      });
    } else {
      // Select all rows on the current page
      this.rows.forEach((row: any) => {
        if (!row.selected) {
          row.selected = true;
          this.currentSelectingItems.push(row);  // Add to selected items
        }
      });
    }
  }

  // Check if all rows are selected
  isAllSelected() {
    if(this.currentSelectingItems.length > 0){
      // Check if all rows on the current page (this.rows) are selected.
      return this.rows.every((row: any) => row.selected);
    }else {
      return false
    }
  }

  // Implement this function to update the table checkboxes to match the current selection of rows.
  updateSelectingRows() {
    // Map array1 and update the 'selected' field
    this.rows = this.rows.map((item: any) => {
      // Check if there is a matching id in array2
      const match = this.currentSelectingItems?.some(element => element.id === item.id);
      
      // Set 'selected' to true if there is a match, false otherwise
      return { ...item, selected: match };
    });
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  getFilterColumns() {
    return this.att?.map((col: any) => {
      return {
        name: col.name,
        displayName: col.displayName,
        dataType: this.getColumnDataType(col.dataType),
        options: col?.dataType === "category" ? col.options : undefined,
      };
    });
  }

  private getColumnDataType(
    attr: string
  ): "string" | "number" | "date" | "category" | "boolean" {
    switch (attr) {
      case "STRING_VAR":
      case "TEXT":
      case "LINK":
        return "string";
      case "TIME_STAMP":
        return "date";
      case "BOOLEAN":
        return "boolean";
      case "INTEGER":
      case "SERIAL":
      case "DECIMAL":
      case "AUTO":
        return "number";
      default:
        return "string";
    }
  }
  onSort(e: any) {
    const sorters = e.sorts.map((sort: any) => {
      const props: string[] = sort.prop.split(",");
      let prop: any = props.length === 1 ? props[0] : [];
      if (props.length > 1) {
        prop = props.find((item) => item.endsWith("_code"));
      }
      return { direction: sort.dir.toUpperCase(), property: prop };
    });
    this.dialogEventSubject.next({
      pageNubmer: this.pageNumber,
      filters: this.filters,
      sorters: sorters
    });
  }
}
