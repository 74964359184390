<!-- <div [ngClass]="overLay ? 'overlay-container' : ''"> -->
  <div *ngIf="!proceed" class="container">
    <div class="logo">
        <div class="name">
            Access to the SPRICED Plugin is restricted to one workbook at a time. Please close the current instance of
            the SPRICED Plugin and reload to continue.
        </div>
        <button mat-button (click)="onSubmit()" class="button">Reload</button>
    </div>
</div>
<div style="display: contents;" *ngIf="proceed">
  <div *ngIf="overLay" class="loader"><mat-spinner color="warn"></mat-spinner><span class="text {{message?.type}}">{{contentName}}</span></div>
    <div class="header">
    <div class="heading-container bg-primary-default h-10">
      <div class="mat_dropdown">
        <mat-label class="label">Model:</mat-label>
        <mat-form-field appearance="outline" class="form-field">
          <!-- <mat-label>Model:</mat-label> -->
          <mat-select [panelClass]="'entity-dropdown'" matNativeControl (valueChange)="handleModelChange($event)"
            [(value)]="defaultModelId">
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" [ngModelOptions]="{standalone:true}" ngModel
                (ngModelChange)="HandleSearchModel($event)"></ngx-mat-select-search>
            </mat-option>
            <mat-option value="" class="option">--Select--</mat-option>
            <mat-option *ngFor="let model of filteredModelList" [value]="model.id">
              {{model.displayName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button class="cursor-pointer text-white" (click)="logout()" title="Logout">
        <mat-icon class="icon items-center text-base cursor-pointer">logout</mat-icon>
      </button>
    </div>

    <div class="icon-grp">

      <button class="h-full flex flex-row cursor-pointer pr-1 border-r-primary-light border-solid"
        (click)="openDialogBox(selectedEntity)"
        [ngClass]="[!showFilter ? 'disable' : 'text-white',filter.length!==0 || selectedHeaders.length!==0 ?'filterbg':'']"
        title="Filter" [disabled]="!showFilter">
        <mat-icon class="icon items-center text-base cursor-pointer">filter_list</mat-icon>
      </button>
      <div style="border-right-width: 1px"></div>

      <button class="h-full flex flex-row cursor-pointer pr-1 border-r-primary-light border-solid"
        (click)="onSavedFilter()" [ngClass]="!entity ? 'disable' : 'text-white'" [title]="'Saved Filters'"
        [disabled]="!entity">
        <mat-icon class="icon items-center text-base cursor-pointer">menu</mat-icon>
      </button>
      <div style="border-right-width: 1px"></div>

      <button class="h-full flex flex-row cursor-pointer pr-1 border-r-primary-light border-solid"
        (click)="validateOrPublishRecords('validate')" [ngClass]="!entityId ? 'disable' : 'text-white'" [title]="'Validate Rules'"
        [disabled]="!entityId || disablePublishButton">
        <mat-icon class="icon items-center text-base cursor-pointer">check</mat-icon>
      </button>
      <div style="border-right-width: 1px"></div>
      <button class="h-full flex flex-row cursor-pointer pr-1 border-r-primary-light border-solid"
        (click)="showHideStatus(btnTitle)" [ngClass]="!entityId ? 'disable' : 'text-white'" [title]="btnTitle"
        [disabled]="!entityId">
        <mat-icon class="icon items-center text-base cursor-pointer">{{icon_name}}</mat-icon>
      </button>
      <div style="border-right-width: 1px"></div>
      <button (click)="publish()" class="h-full flex flex-row cursor-pointer pr-1 border-r-primary-light border-solid"
        [ngClass]="disablePublishButton ? 'disable' : 'text-white'" title="Publish"
        [disabled]="disablePublishButton">
        <mat-icon class="icon items-center text-base cursor-pointer">publish</mat-icon>
      </button>
      <div style="border-right-width: 1px"></div>
      <button [ngClass]="!(addedSheetsNames.includes('Entity_' +selectedEntity?.id) && (loadEntityData.length > 0 || entityId))? 'disable' : 'text-white'"
        [disabled]="!(addedSheetsNames.includes('Entity_' +selectedEntity?.id) && (loadEntityData.length > 0 || entityId))"
        class="h-full flex flex-row cursor-pointer pr-1 border-r-primary-light border-solid"
        (click)="refreshExcelData(selectedEntity)" title="Refresh">
        <mat-icon class="icon items-center text-base cursor-pointer">refresh</mat-icon>
      </button>
      <div style="border-right-width: 1px"></div>
      <button [ngClass]="!deletionRowRange ? 'disable' : 'text-white'"
        [disabled]="!deletionRowRange"
        (click)="deleteRows(deletionRowRange)"
        class="h-full flex flex-row cursor-pointer pr-1 border-r-primary-light border-solid"
        title="Delete">
        <mat-icon class="icon items-center text-base cursor-pointer">delete</mat-icon>
      </button>
      <div style="border-right-width: 1px"></div>
      <button class="h-full flex flex-row cursor-pointer pr-1 border-r-primary-light border-solid text-white"
      (click)="openSettings()" title="settings"
      [ngClass]="!entity ? 'disable' : 'text-white'"
      [disabled]="!entity">
      <mat-icon class="icon items-center text-base cursor-pointer">settings</mat-icon>
    </button>
    </div>

  </div>

  <div class="entity-list-table">
    <div class="no-record" *ngIf="entityList.length === 0">
      <span> No record found... </span>
    </div>

    <div class="table-record" *ngIf="entityList.length > 0">
      <div class="serach-box">
        <input #searchInput (keyup)="searchInputSubject.next(searchInput.value)" matInput
          placeholder="Search Entity.." />
      </div>
      <ul>
        <ng-container *ngFor="let item of filteredEntityList">
          <li [ngClass]="selectedEntity?.id === item?.id ? 'selected' : ''" (click)="entitySelection(item)"> <img
              class="entity-logo" src="./../../../assets/file.png" /><span> {{item.displayName}} </span></li>
        </ng-container>
        <li *ngIf="filteredEntityList.length === 0"> No entity found. </li>
      </ul>
    </div>
  </div>
  <div class="footer message-container" *ngIf="!!message || loading && !overLay">
    <div class="message-box {{message.type}}" *ngIf="!loading && !!message"> <span [class]="message.type" class="ml-2">
        {{message.message}} </span>
        <mat-icon (click)="close()" class="close-icon ml-3">close</mat-icon>
    </div>
    <div *ngIf="loading" class="loading"> {{contentName}} <mat-spinner></mat-spinner> </div>
  </div>

  <p class="version">v{{version}}</p>
</div>