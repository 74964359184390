<div class="loader" *ngIf="loading">Loading...<mat-spinner></mat-spinner>
</div>
<div *ngIf="!loading">
  <label class="text-secondary-dark m-2 font-bold text-lg">Settings</label>
  <mat-card class="m-5">
    <mat-label class="m-2 mt-4"><strong>{{
        currentEntity?.displayName || currentEntity?.name
        }}</strong></mat-label>
    <form [formGroup]="columnForm">
      <mat-form-field appearance="outline" class="form-field p-4 w-full" color="primary">
        <mat-label> My defaults</mat-label>
        <mat-select multiple formControlName="column" [panelClass]="'attribute-select-panel'" color="primary">
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Search" formControlName="search"></ngx-mat-select-search>
          </mat-option>
          <mat-option (click)="toggleAll()" #all value="All">Restore defaults</mat-option>
          <mat-option (click)="togglePerColumn()" *ngFor="let item of filteredlList" [value]="item.name">{{
            item.name }}</mat-option>

        </mat-select>
      </mat-form-field>
    </form>
  </mat-card>

  <mat-card class="m-5">
    <mat-label class="m-2 mt-4"><strong>All Entity</strong></mat-label>
    <mat-checkbox color="primary" [(ngModel)]="showSystem">
      Show System Attribute</mat-checkbox>
  </mat-card>
  <div class="footer">
    <button class="mr-2 " (click)="applySettings()">
      Apply
    </button>
    <button class="mr-2 cancel-btn" (click)="onCancel()">
      Cancel
    </button>
  </div>
</div>