import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Criteria, PageData, RequestUtilityService } from "src/app/services/utility/request-utility.service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class FilterListService {
    api_url: string;

    constructor(
        private http: HttpClient,
        private requestUtility: RequestUtilityService
      ) {
        this.api_url = environment.API_DATA_URL;
      }
loadFilters(criteria:Criteria)
  {
    const url = this.requestUtility.addCriteria(
      `${this.api_url}/filter`,
      criteria
    );
    return this.http.get<PageData>(url);
  }
  addFilters(filter:any)
  {
    return this.http.post(`${this.api_url}/filter?version=${environment.VERSION}`, filter);
  }
  deleteFilter(id:number)
  {
    return this.http.delete(`${this.api_url}/filter/${id}?version=${environment.VERSION}`);
  }
  editFilter(filter:any)
  {
    return this.http.put(`${this.api_url}/filter?version=${environment.VERSION}`,filter);
  }
}